import React, { useRef, useEffect } from 'react';

const OtpInput = ({ value, onChange }) => {
  const inputs = useRef([]);

  useEffect(() => {
    // Focus the first input element when the component mounts
    if (inputs.current[0]) {
      inputs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...value];
    newOtp[index] = element.value;
    onChange(newOtp);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === "Backspace") {
      if (value[index] === "") {
        e.preventDefault(); 
        if (inputs.current[index - 1]) {
         
          inputs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...value];
        newOtp[index] = "";
        onChange(newOtp);
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    const newOtp = paste.split("").slice(0, 6);
    onChange(newOtp);

    if (inputs.current[5]) {
      inputs.current[5].focus();
    }
  };

  return (
    <div onPaste={handlePaste} className="flex justify-center space-x-2">
      {value.map((data, index) => (
        <input
          className="w-12 h-12 text-center text-lg border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          type="tel"
          name="otp"
          maxLength="1"
          key={index}
          value={data}
          ref={(el) => (inputs.current[index] = el)}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
};

export default OtpInput;
