/* products/cateogry/1 => results[0]*/

import { CONST_DATA } from "./constData";
import  '../utils/DatetimeExtension'

function FoxProduct(productByCat, en) {
  const {
    product_code,
    quantity = 1,
    category_code: cat = null,
    sub_category_code: subcat = null,
    product_details: {
      sku = null,
      short_desc_en = null,
      short_desc_local = null,
      slug_url = null,
      ProductImages = [],
      badge = false,
      ProductPrices = [],
      ProductOrigin,
      color = null,
      brand = null,
      full_desc_en = null,
      full_desc_local = null,
    } = {},
  } = productByCat;

  const [productImage] = ProductImages;
  const [productPrice] = ProductPrices;
 
  
  console.log(ProductOrigin.unit_vn);
  return {
    product_code,
    sku,
    name: en ? short_desc_en : short_desc_local,
    slug: slug_url || product_code,
    quantity,
    image: productImage?.image || null,
    badge,
    price: productPrice?.normal_price_in_vat || null,
    price_ex_vat: productPrice?.normal_price_ex_vat || null,
    retail_price: getPromotionPrice(productPrice,PRICE_TYPE.promotion_price_in_vat),
    retail_price_ex_vat:getPromotionPrice(productPrice,PRICE_TYPE.promotion_price_ex_vat),
    vat_code: productPrice.vat_code,
    vat_rate: productPrice.vat_rate,
    color,
    brand,
    cat,
    subcat,
    unit: en? ProductOrigin?.unit_en: ProductOrigin?.unit_vn ,
    des: en ? full_desc_en : full_desc_local || (en ? short_desc_en : short_desc_local),
  };
}

function FoxProductSearch(productByCat, en) {
  const {
    product_code,
    quantity = 1,
    category_code: cat = null,
    sub_category_code: subcat = null,
    sku = null,
    short_desc_en = null,
    short_desc_local = null,
    slug_url = null,
    ProductImages = [],
    badge = false,
    ProductPrices = [],
    ProductOrigin,
    color = null,
    brand = null,
    full_desc_en = null,
    full_desc_local = null,
  } = productByCat;

  const [productImage] = ProductImages;
  const [productPrice] = ProductPrices;
  return {
    product_code,
    sku,
    name: en ? short_desc_en : short_desc_local,
    slug: slug_url || product_code,
    quantity,
    image: productImage?.image || CONST_DATA.DEFAULT_IMG,
    badge,
    price: productPrice?.normal_price_in_vat || null,
    price_ex_vat: productPrice?.normal_price_ex_vat || null,
    retail_price:getPromotionPrice(productPrice,PRICE_TYPE.promotion_price_in_vat),
    retail_price_ex_vat:getPromotionPrice(productPrice,PRICE_TYPE.promotion_price_ex_vat),
    vat_code:ProductOrigin.vat_code,
    vat_rate: ProductOrigin.vate_rate,
    color,
    brand,
    cat,
    subcat,
    des: en
      ? full_desc_en
      : full_desc_local || (en ? short_desc_en : short_desc_local),
      unit: en? ProductOrigin?.unit_en: ProductOrigin?.unit_vn ,
  };
}

function FoxProductSearchElastic(productByCat, en) {
  const  
  {
    _index,
    _type ,
    _id,
    _score,
    _source,
    fields,
    retail_prices=[],
  } =productByCat;


  let productImage;
  if(_source?.ProductImages!==undefined){
    productImage = _source?.ProductImages[0];
  }

  const [productPrice] = fields.retail_prices;
 
  return {
    product_code: _id,
    sku:_source["sku"],
    name: en ? _source.full_desc_en : _source.full_desc_local,
    slug: _source["slug_url"] || _id,
    quantity:1,
    image: productImage?.image || CONST_DATA.DEFAULT_IMG,
    badge:null,
    price: productPrice?.perm_price || null,
    price_ex_vat: (productPrice.perm_price)-(productPrice.perm_price)*productPrice.vat_rate/100,
    retail_price: productPrice?.price||null,
    retail_price_ex_vat: productPrice?.selling_price_exvat || null,
    vat_code:productPrice.vat_code,
    vat_rate:productPrice.vat_rate,
    color:_source["color"],
    brand:_source["brand"],
    cat:_source["cat"],
    subcat:_source["sub_cat"],
    des: en
      ? _source["full_desc_en"]
      :_source["full_desc_local"]  || (en ? _source["short_desc_en"] : _source["short_desc_local"]),
      unit: productPrice.mmunit
  };
}
function FoxProduct_Home(productByCat, en) {
  const {
    product_code,
    sku = null,
    short_desc_en,
    short_desc_local,
    slug_url,
    quantity = 1,
    ProductImages = [],
    badge = false,
    ProductPrices = [],
    color = null,
    brand = null,
    sub_category_code: subcat = null,
    Categories = [],
    full_desc_en,
    full_desc_local,
    ProductOrigin
  } = productByCat;

  const [productImage] = ProductImages;
  const [productPrice] = ProductPrices;
  const [category] = Categories;
  
  return {
    product_code,
    sku,
    name: en ? short_desc_en : short_desc_local,
    slug: slug_url || product_code,
    quantity,
    image: productImage?.image || null,
    badge,
    price: productPrice?.normal_price_in_vat || null,
    price_ex_vat: productPrice?.normal_price_ex_vat || null,
    retail_price: getRetailPrice(productPrice,PRICE_TYPE.price_in_vat),
    retail_price_ex_vat: getPromotionPrice(productPrice,PRICE_TYPE.price_ex_vat),
    vat_code: productPrice?.vat_code || null,
    vat_rate: productPrice?.vat_rate || null,
    color,
    brand,
    cat: category?.category_code || null,
    subcat,
    des: en ? full_desc_en : full_desc_local || (en ? short_desc_en : short_desc_local),
    cat_name: en ? category?.name_en : category?.name_local,
    unit: en? ProductOrigin?.unit_en: ProductOrigin?.unit_vn ,
  };
}

function FoxProduct_Home_Price(productByCat, en) {
  const {
    product_code,
    sku = null,
    short_desc_en,
    short_desc_local,
    slug_url,
    quantity = 1,
    ProductImages = [],
    badge = false,
    ProductPrices = [],
    ProductOrigin,
    color = null,
    brand = null,
    sub_category_code: subcat = null,
    Categories = [],
    full_desc_en,
    full_desc_local,
  } = productByCat.product_details || {};

  const [productImage] = ProductImages;
  const [productPrice] = ProductPrices;
  const [category] = Categories;
  
  return {
    product_code,
    sku,
    name: en ? short_desc_en : short_desc_local,
    slug: slug_url || product_code,
    quantity,
    image: productImage?.image || null,
    badge,
    price: productPrice?.normal_price_in_vat || null,
    price_ex_vat: productPrice?.normal_price_ex_vat || null,
    retail_price: getRetailPrice(productPrice,PRICE_TYPE.price_in_vat),
    retail_price_ex_vat: getPromotionPrice(productPrice,PRICE_TYPE.price_ex_vat),
    vat_code: productPrice.vat_code,
    vat_rate:productPrice.vat_rate,
    color,
    brand,
    cat: category?.category_code || null,
    subcat,
    des: en ? full_desc_en : full_desc_local || (en ? short_desc_en : short_desc_local),
    cat_name: en ? category?.name_en : category?.name_local,
    unit: en? ProductOrigin?.unit_en: ProductOrigin?.unit_vn ,
  };
}

function hasPromotion(fromDate, toDate){
  if(fromDate===null || fromDate===undefined) return false;
  if(toDate===null || toDate===undefined) return false;
  let currentDate = new Date();
  if(currentDate.isBetweenDateTime(new Date(fromDate),new Date(toDate) )){
    return true;
  }
  return false;
}
function getPromotionPrice(productPrice, price_type ){
  if(!hasPromotion(productPrice.promotion_start, productPrice.promotion_end)){
    if(price_type===PRICE_TYPE.price_ex_vat) return productPrice?.normal_price_ex_vat
    if(price_type===PRICE_TYPE.price_in_vat) return productPrice?.normal_price_in_vat
  }
  else {
    if(price_type===PRICE_TYPE.price_ex_vat) return productPrice?.promotion_price_ex_vat
    if(price_type===PRICE_TYPE.price_in_vat) return productPrice?.promotion_price_in_vat
  }
}

function hasOtherPrice(fromDate, toDate){
  if(fromDate===null || fromDate===undefined) return false;
  if(toDate===null || toDate===undefined) return false;
  let currentDate = new Date();
  if(currentDate.isBetweenDateTime(new Date(fromDate),new Date(toDate) )){
    return true;
  }
  return false;
}
function getRetailPrice(productPrice, price_type) {
  // Check for flash price
  if (hasOtherPrice(productPrice.flash_start, productPrice.flash_end)) {
    if (price_type === PRICE_TYPE.price_ex_vat) return productPrice?.flash_price_ex_vat;
    if (price_type === PRICE_TYPE.price_in_vat) return productPrice?.flash_price_in_vat;
  }

  // Check for exclusive price
  if (hasOtherPrice(productPrice.exclusive_start, productPrice.exclusive_end)) {
    if (price_type === PRICE_TYPE.price_ex_vat) return productPrice?.exclusive_price_ex_vat;
    if (price_type === PRICE_TYPE.price_in_vat) return productPrice?.exclusive_price_in_vat;
  }

  // Check for promotion price
  if (hasOtherPrice(productPrice.promotion_start, productPrice.promotion_end)) {
    if (price_type === PRICE_TYPE.price_ex_vat) return productPrice?.promotion_price_ex_vat;
    if (price_type === PRICE_TYPE.price_in_vat) return productPrice?.promotion_price_in_vat;
  }

  // Default to normal price if no other prices are applicable
  if (price_type === PRICE_TYPE.price_ex_vat) return productPrice?.normal_price_ex_vat;
  if (price_type === PRICE_TYPE.price_in_vat) return productPrice?.normal_price_in_vat;

  return null; // Return null if the price type doesn't match
}
const PRICE_TYPE = {
 
  price_ex_vat: 'price_ex_vat',
  price_in_vat: 'price_in_vat'
};

/* Categories/15 => results[0].child_categories[0] */

function FoxCat(cat, en) {
  let res = {};
  res.category_code = cat.category_code ?? null;
  res.name = en ? cat?.name_en : cat.name_local ?? null;
  res.image = cat.image ?? false;
  return res;
}

/* BrandTypes/1 => results[0].BrandOrigins */

function FoxBrand(brandOrigin, en) {
  let res = {};
  res.id = brandOrigin.id;
  res.code = brandOrigin.code;
  res.type = brandOrigin.type_id;
  res.name = en ? brandOrigin.name_en : brandOrigin.name_local;
  return res;
}
function FoxCartItem(item, en) {
 
  const {
 
    ProductImages = [],
    
    ProductPrices = [],
    ProductOrigin: { vat_code = null, vat_rate = null } = {},
    sub_category_code: subcat = null,
   
  } = item.product_details || {};

  const [productImage] = ProductImages;
  const [productPrice] = ProductPrices;
  

  return {
    product_code: item.product_code,
    sku: item.product_details.sku,
    name: en ? item.product_details.name_en : item.product_details.name_local,
    slug: item.product_details.slug_url,
    quantity: item.quantity,
    image: productImage?.image ?? "sampleImage.jpg",
    badge: false,
    // only care about final price
    // set default price the same retail_price(the price has promotion )
    price:getRetailPrice(productPrice,PRICE_TYPE.price_in_vat),
    price_ex_vat:getPromotionPrice(productPrice,PRICE_TYPE.price_ex_vat),
    retail_price: getRetailPrice(productPrice,PRICE_TYPE.price_in_vat),
    retail_price_ex_vat: getPromotionPrice(productPrice,PRICE_TYPE.price_ex_vat),
    vat_code: productPrice.vat_code,
    vat_rate: productPrice.vat_rate,
    unit_name: en
      ? productPrice.mmunit_en
      : productPrice.mmunit,
    unit_en: productPrice.mmunit_en,
    unit_vn: productPrice.mmunit,
    unit_code: item.product_details.ProductOrigin.unit_code,
    mmunit_code: productPrice.mmuncode,
    color: null,
    brand: null,
    cat: null,
    subcat: null,
    des: en
      ? item.product_details.full_desc_en
      : item.product_details.full_desc_local,
    isSelected: true,
  };
}
export {
  FoxProduct,
  FoxCat,
  FoxBrand,
  FoxProduct_Home,
  FoxProductSearch,
  FoxProduct_Home_Price,
  FoxCartItem,
  FoxProductSearchElastic
};
