import React, { useState, useRef, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { BiCaretDown,BiCaretUp } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import {
  FaSearch,
  FaUser,
  FaCaretDown,
  FaShoppingCart,
  FaMapMarkerAlt,
} from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsSuitHeartFill } from "react-icons/bs";
import {
  toggleCategory,
  resetCategory,
  setListStores,
  setUseSelectedStore,
  updateUserInfo,
  createOrUpdateCart,
  createOrUpdateDummy,
  resetCart,
  updateOrderDelivery,clearAllWishList
} from "../../../redux/FoxSlice";
// import { categoryList, productList } from "../../../common.js/db";
import {
  // FoxProduct,
  FoxProductSearch,
  FoxProductSearchElastic
} from "../../../common.js/transformation";
import _ from "lodash";
import { LableItem } from "../../../components/LabelLanguge/LabelItem";
// import { ContentItem } from "../../contentLanguage/ContentItem";
import { GetContentLangByCode } from "../../contentLanguage/ContentFactory";
import { getAllStore } from "../../../services/MasterDataServices";
import { SearchProduct } from "../../../services/SearchServices";
import CurrencyComponent from "../../Currency/CurrencyFormat";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";
import { genToken } from "../../../services/AccountServices";
import { CreateCart } from "../../../services/CartServices";
import  slug  from "../../../utils/slug";

const HeaderBottom = () => {
  const products = useSelector((state) => state.FoxReducer.products);
  const store = useSelector((state) => state.FoxReducer.useSelectedStore);
  const En = useSelector((state) => state.FoxReducer.En);
  const userInfo = useSelector((state) => state.FoxReducer.userInfo);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [selectedStore, setSelectedStore] = useState(store.store_name);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const ref = useRef();
  const ref2 = useRef();
  const dispatch = useDispatch();

  const [stores, setStores] = useState([]);
  const [filteredStores, setFilteredStores] = useState(stores);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const [isLogined, setIsLogined] = useState(false);
  const [data, setData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const _wishlist = useSelector((state) => state.FoxReducer.wishlist) || [];
  const hasItems = _wishlist.length > 0;

  const handleStoreChange = (e) => {
    const value = e.target.value;
    setSelectedStore(value);
    if (value === "") {
      setFilteredStores(stores);
    } else {
      setFilteredStores(
        stores.filter((store) =>
          store.store_name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
    setShowDropdown(true);
  };
  const handleKeyDown = (e) => {
    if (showSearchResult && filteredProducts.length > 0) {
      if (e.key === "ArrowDown") {
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredProducts.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredProducts.length - 1
        );
      } else if (e.key === "Enter" && highlightedIndex !== -1) {
        const selectedItem = filteredProducts[highlightedIndex];
        const item = FoxProductSearchElastic(selectedItem, En);
        navigate(`/product/${item.slug}`, { state: { item: item } });
        setSearchQuery("");
        setShowSearchResult(false);
      }
    }
  };

  const handleOptionClick = (store) => {
    setSelectedStore(store.store_name);
    dispatch(setUseSelectedStore(store));
    setShowDropdown(false);
    inputRef.current.focus(); // Keep the input focused
  };
  const handleShowMap = (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          ShowMap({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  function ShowMap(pos) {
    const url = `https://www.google.com/maps/dir/${pos.latitude},${pos.longitude}/10.86364373247677, 106.64771179594022`;
    const width = window.screen.width;
    const height = window.screen.height;
    const left = 0;
    const top = 0;

    window.open(
      url,
      "_blank",
      `fullscreen=yes, width=${width}, height=${height}, left=${left}, top=${top}`
    );
  }
  const handleBlur = (e) => {
    if (!containerRef.current.contains(e.relatedTarget)) {
      setShowDropdown(false);
    }

    if (selectedStore === "" || selectedStore === undefined) {
      dispatch(setUseSelectedStore({}));
    }
  };
  const handleLogout =async (e)=>{
    
    e.preventDefault();
    navigate('/logout/return-url=home')
    
  }
  const fetchStores = async () => {
    try {
      setIsLoadingStore(true);
      const data = await getAllStore();
      if (data.success) {
        dispatch(setListStores(data.result));
        setStores(data.result);
        
      
        setFilteredStores(data.result);
        if (selectedStore === "" || selectedStore === undefined) {
          let st =data.result.find((store) => store.store_code.toString() === CONST_DATA.STORE_DEFAULT);
          dispatch(setUseSelectedStore(st));
          setSelectedStore(st.store_name);
        }
      }
      setIsLoadingStore(false);
    } catch (error) {
      setIsLoadingStore(false);
      console.error("Failed to fetch stores:", error);
    }
  };
  const menuRef = useRef(null);
  const searchResultRef = useRef([]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      const clickedTag = e.target.nodeName;
      if (ref?.current?.contains(e.target) && clickedTag.toLowerCase()!=="button"  && clickedTag.toLowerCase()!=="span") {
        setShow((prev) => !prev);
      } else if(clickedTag.toLowerCase()!=="button" && clickedTag.toLowerCase()!=="span") {
        console.log('ref_value:' + false);
        setShow(false);
      }

      if (ref2?.current?.contains(e.target)) {
        setShowUser((prev) => !prev);
      } else {
        setShowUser(false);
      }
      setSearchQuery("");
    };
    // const getStores = async () => {
    //   await fetchStores();
    // };
    //getStores();
    const fetchProducts = async () => {
      try {
        let props = {
          url: `${BackendApi.allCategories.url}/${CONST_DATA.CHANNEL}?page=1&size=24&valid=true&active=true`,
          method: BackendApi.allCategories.method,
        }
        const data1 = await fetchData(props);
        if (data1.success) {
          setData(data1.results[0].child_categories);
        }
        else
          setData(null);

      } catch (err) {
        alert(err);
      } finally {
      }
    }
    fetchProducts();
    
    if (userInfo) setIsLogined(true);
    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [dispatch, showSearchResult, navigate, isSearching, userInfo]);
  useEffect(() => {
    if (highlightedIndex !== -1 && searchResultRef.current[highlightedIndex]) {
      searchResultRef.current[highlightedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedIndex]);
  let filteredData = data;
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      (async () => {
        try {
          setIsSearching(true);
          let products = await SearchProduct(query,CONST_DATA.STORE_DEFAULT);
          console.log(products);
          setShowSearchResult(true);
          if (products.length > 0) {
            setFilteredProducts(products);
           
          } else setFilteredProducts([]);
          setIsSearching(false);
        } catch (error) {
          setIsSearching(false);
          console.error("Error searching products:", error);
        }
      })();
    }, 500),
    []
  );

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setFilteredProducts([]);
    setHighlightedIndex(-1);

    debouncedSearch(query);
    //setSelectedIndex(-1); // Reset selected index when the search query changes

  };
  const handleCreateUser = (e)=>{
    e.preventDefault();
    console.log('handleCreateUser');
    navigate('signin', { state: { returnUrl: '/edit-profile' } });
    console.log('/signin');
  }
  const handleToggleCategory = (category) => {
    dispatch(resetCategory());
    dispatch(toggleCategory(category));
  };
  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleChildClick = (childName,child_categories, cate_item) => {
    dispatch(resetCategory());
    dispatch( toggleCategory(cate_item));
    navigate(`/category/${childName}`,{ state: { child_categories }});
  };
  function convertToSlug(str) {
    str = str.toLowerCase();
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.replace(/[^a-z0-9\s-]/g, '')  
             .replace(/\s+/g, '-')           
             .replace(/-+/g, '-');           
  
    return str;
  }
  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-16">
          <div
            ref={ref}
            className="flex h-14 w-60 cursor-pointer items-center gap-2 text-primeColor"
          >
            <HiOutlineMenuAlt4 className="w-5 h-5" />
            <p className="text-[16px] font-normal">
              <LableItem code="C.HeaderBottom.ShopByCategory"></LableItem>
            </p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-16 z-50 bg-menuBG w-80 text-menuText h-auto p-4 pb-6" /* NTT changed w-64 -> w-80 */
              >
                <nav className="text-white">
                <ul className="p-4 space-y-4" ref={menuRef}>
                    {filteredData.map((item, index) => (
                      <li key={index} className="relative" style={{ borderBottom: '1px solid #ebebeb' }}>
                        <button
                          className="flex items-center justify-between w-full focus:outline-none" style={{ fontWeight: 'bold' }}
                          onClick={() => handleClick(index)}
                        >
                          {En ? item.name_en : item.name_local}
                          <span className="ml-2">
                            {openIndex === index ? <BiCaretUp/>: <BiCaretDown/>} {/* NTT changed icon */} 
                          </span>
                        </button>
                        {openIndex === index && (
                          <ul className="mt-2 rounded shadow-lg">
                            {item.child_categories.map((child, childIndex) => (
                              <li onClick={() => handleChildClick(En ? slug(child.name_en) + "_" + child.category_code : slug(child.name_local) + "_" + child.category_code, item.child_categories, child)} key={childIndex} className="px-4 py-2 whitespace-nowrap flex items-center" >
                                <img src={child.image??`${window.location.origin}/sampleImage.jpg`} className="w-10 h-10 mr-3" alt=""/> {/* NTT changed w-6 -> w-8 */}
                                {En ? child.name_en : child.name_local}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>

                </nav>
                
              </motion.ul>
            )}
          </div>
          <div
            ref={containerRef}
            onBlur={handleBlur}
            className="relative w-full lg:w-[90%] h-10 text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl"
          >
            {/* {selectedStore ? (
              <FaMapMarkerAlt
                onClick={handleShowMap}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="text-primeColor cursor-pointer"
              />
            ) : (
              <FaMapMarkerAlt className="text-gray-400" />
            )}
            {showTooltip && (
              <div className="tooltip absolute bg-black text-white text-center rounded p-1 text-xs bottom-full left-1/2 transform -translate-x-1/2">
                <LableItem code="C.HeaderBottom.ClickShowMap"></LableItem>
              </div>
            )} */}
            {/* <input
              ref={inputRef}
              type="text"
              className="flex-none h-[70%] outline-none text-[12px] bg-white border border-gray-300 rounded-md p-2 w-64"
              placeholder="Chọn cửa hàng gần bạn"
              value={selectedStore}
              onChange={handleStoreChange}
              onFocus={() => setShowDropdown(true)}
            />
            {showDropdown && (
              <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-y-auto z-10">
                {filteredStores.map((store) => (
                  <li
                    key={store.store_code}
                    className="p-2 hover:bg-gray-200 cursor-pointer"
                    onMouseDown={() => handleOptionClick(store)}
                  >
                    {store.store_name}
                  </li>
                ))}
                {filteredStores.length === 0 && (
                  <li className="p-2 text-gray-500">No stores found</li>
                )}
              </ul>
            )} */}
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              ref={inputRef}
              onKeyDown={handleKeyDown}
              placeholder={GetContentLangByCode(
                "C.HeaderBottom.SearchInput.Plh"
              )}
            />
            <FaSearch className="w-5 h-5" />
            {searchQuery && searchQuery !== "" && showSearchResult && (
              <div
                className={`w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {filteredProducts.length>0?(<>
                  {filteredProducts.map((p, index) => {
                  let item = FoxProductSearchElastic(p, En);
                  return (
                    <div
                      onClick={() =>
                        navigate(`/product/${item.slug}`, {
                          state: { item: item },
                        }) & setSearchQuery("")
                      }
                      key={item.product_code}
                      ref={(el) => (searchResultRef.current[index] = el)}

                      className={`max-w-[600px] h-28 mb-3 flex items-center gap-3 px-3 ${
                        highlightedIndex === index
                          ? "bg-gray-300"
                          : "bg-gray-100"
                      }`}
                    >
                      <img className="w-24" src={item.image} alt={item.name} />
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-lg">{item.name}</p>
                        <p className="text-xs">
                          {item.name.length > 100
                            ? `${item.name.slice(0, 100)}...`
                            : item.name}
                        </p>
                        <p className="text-sm">
                          <LableItem code="C.HeaderBottom.Price"></LableItem>:{" "}
                          <span className="text-primeColor font-semibold">
                            <CurrencyComponent
                              price={item.retail_price}
                            ></CurrencyComponent>/{item.unit}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>):( <div className="flex flex-col gap-1"><p>No products found</p> </div>) }
                
              </div>
            )}
          </div>
          <div className="flex gap-4 mt-4 lg:mt-0 lg:pl-4 items-center cursor-pointer relative">
            <div ref={ref2} className="flex">
              {isLogined && ( <FaUser  className="text-primeColor cursor-pointer" />)}
              {!isLogined && ( <FaUser   />)}
              <FaCaretDown />
            </div>
            {showUser && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-6 -left-6 z-50 bg-menuBG w-44 text-menuText h-auto p-4 pb-6"
              >
                {isLogined && (
                  <>
                    <Link to ="edit-profile">
                      <li
                        key="profile"
                        className="flex font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      >
                        <LableItem code="C.HeaderBottom.Profile"></LableItem>
                      </li>
                    </Link>
                    <Link to="/order-history">
                      <li
                        key="signin"
                        className="flex font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      >
                        <LableItem  code="C.HeaderBottom.Orders"></LableItem>
                      </li>
                    </Link>
                    <Link  onClick={handleLogout} to="#" >
                      <li
                        key="logout"
                        className="flex font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      >
                        <LableItem code="C.HeaderBottom.Logout"></LableItem>
                      </li>
                    </Link>
                  </>
                )}
                {!isLogined && (
                  <>
                    <Link to="/signin">
                      <li
                        key="signin"
                        className="flex font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      >
                        <LableItem code="C.HeaderBottom.Login"></LableItem>
                      </li>
                    </Link>
                    <Link onClick={handleCreateUser} >
                      <li
                        key="signup"
                        className="flex font-normal hover:font-bold items-center text-lg text-gray-200 hover:underline underline-offset-[4px] decoration-[1px] hover:text-white md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                      >
                         <LableItem code="C.HeaderBottom.SignUp"></LableItem>
                      </li>
                    </Link>
                  </>
                )}
              </motion.ul>
            )}
            <Link to="/cart">
              <div className="relative">
                <FaShoppingCart />
                <span className="absolute font-titleFont -top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {products?.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
            <Link to="/wishlist">
              <BsSuitHeartFill style={{ color: hasItems ? 'red' : 'black' }} />
            </Link>
            
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
