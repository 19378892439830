export const CONST_DATA = {
    EN_LANGUAGE: "en",
    VN_LANGUAGE:"vi",
    CHANNEL: "1",
    FOX_GW:"http://172.26.16.163:1880",
    FOX:"https://172.26.16.188:3030/api/v1",
    FOX_GW_PROXY:"fox-gw",
    FOX_PROXY: "fox/api/v1",
    FOX_BASE_URL : "https://api-test.mmvietnam.com:5000",
    STORE_DEFAULT: "30002",
    DEFAULT_IMG:"/sampleImage.jpg",
    DELIVERY_ADDRESS_TYPE :"2",
    INVOICE_ADDRESS_TYPE :"1",
    RETRY_REFRESH_KEY:'RETRY_REFRESH_KEY',
    MAX_RETRY_REFRESH_TIMES: 3,
    LAST_RETRY_REFRESH :'LAST_RETRY_REFRESH',
    EXPIRED_IN_MINUTES: 5

}