import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { FoxProduct_Home } from "../../../common.js/transformation";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { CONST_DATA } from "../../../common.js/constData";

const YearProduct = () => {
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    //setData(productList);
    const fetchProducts = async () => {
      try {
        console.log(store_code);
        let props = {
          url: `${BackendApi.allProducts.url}?page=1&size=1&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&attribute_code=7&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]`,
          method: BackendApi.allProducts.method,
        };

        const data1 = await fetchData(props);
        console.log('year_product');
        console.log(data1);
        if (data1.success) {
          setData(data1.results);
        } else setData(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [store_code, En]);
  let filteredData = data;

  return (
    // <Link to="/producttype/7">
    //   <div className="w-full h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
    //     <Image
    //       className="w-full h-full object-cover hidden md:inline-block"
    //       imgSrc={productOfTheYear}
    //     />
    //     <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col md:flex-row items-start md:items-center gap-6 justify-center" style={{right:"10%"}}>
    //       <div className="w-full md:w-2/3 flex flex-col items-start gap-6 justify-center">
    //         <h2 className="text-3xl font-semibold text-primeColor">
    //           {En ? "Product of The year" : "Sản phẩm của năm"}
    //         </h2>
    //         <h3 className="text-1xl font-semibold text-primeColor">
    //           {filteredData && data[0] ? (En? filteredData[0].short_desc_en: filteredData[0].full_desc_local):""}
    //         </h3>
    //         <p className="text-base font-normal text-primeColor max-w-[600px]">
    //           { filteredData && data[0] ?  (En? filteredData[0].short_desc_en: filteredData[0].full_desc_local) :""}
    //         </p>
    //         <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-primeColorDark duration-300 font-bold">
    //           {En ? "View more" : "Xem thêm"}
    //         </button>
    //       </div>
    //       <div className="w-full md:w-1/3">
    //         <Image
    //           className="w-full h-full object-contain"
    //           imgSrc={filteredData && filteredData[0] ? filteredData[0].ProductImages[0].image: ""} // Replace this with the image you want to add on the right side
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </Link>
    <Link to="/producttype/7">
      <div className="bg-cover bg-center  h-auto py-24 px-10 object-fill" style={{backgroundImage:`url(${window.location.origin}/bg_poy.png)`}}>
        <div className="md:w-1/2">
          <p className="font-bold text-sm uppercase">{En ? "Product of The year" : "Sản phẩm của năm"}</p>
          <p className="text-3xl font-bold">{ filteredData && data[0] ?  (En? filteredData[0].short_desc_en: filteredData[0].full_desc_local) :""}</p>
          <p className="text-2xl mb-10 leading-none">{ filteredData && data[0] ?  (En? filteredData[0].full_desc_en: filteredData[0].full_desc_local) :""}</p>
          <a href="#" className="bg-purple-800 py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-gray-200 hover:text-gray-800">Xem chi tiết</a>
          </div>  
      </div>
    </Link>
  );
};

export default YearProduct;
