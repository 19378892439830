import React, { useState, useEffect } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { logoLight, vi, uk } from "../../assets/images";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import { useDispatch } from "react-redux";
import { createUserRegister, toggleLanguage } from "../../redux/FoxSlice";
import Image from "../../components/designLayouts/Image";
import { GetContentLangByCode } from "../../components/contentLanguage/ContentFactory";

import { AddressInput } from "../../components/Address/AddressInput";
import { LoadingButton } from "../../components/Load/LoadingButton";
import {
  GetCustomerInfomation,
  sendLoginOtp,
  UpdateCustomer,
} from "../../services/AccountServices";
import { CONST_DATA } from "../../common.js/constData";
import { isNullOrUndefinedOrStringEmpty } from "../../utils/CheckObjectHelper";
import { toast } from "react-toastify";

const SignUp = () => {
  // ============= Initial State Start here =============

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [customer, setCustomer] = useState({});

  const [customerName, setCustomerName] = useState('');
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState('');
  const [errEmail, setErrEmail] = useState('');
  const [errPhone, setErrPhone] = useState('');
  const [errAddress, setErrAddress] = useState('');
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState('');
  // ============= Event Handler Start here =============
  const [En, setEn] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryProvince, setDeliveryProvince] = useState('');
  const [deliveryDistrict, setDeliveryDistrict] = useState('');
  const [deliveryWard, setDeliveryWard] = useState(null);

  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [invoiceProvince, setInvoiceProvince] = useState(null);
  const [invoiceDistrict, setInvoiceDistrict] = useState(null);
  const [invoiceWard, setInvoiceWard] = useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const addressInit = {
    address: '',
    province:  "79",
    district: "776",
    ward:  "26872",
  };
  const [deliveryAddressInit, setDeliveryAddressInit] = useState(addressInit);
  const [invoiceAddressInit, setInvoiceAddressInit] = useState(addressInit);
  const [dateOfBirth, setDateOfBirth] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangeDeliveryWard = (option) => {
    setDeliveryWard(option);
  };
  const handleChangeDeliveryProvince = async (option) => {
    setDeliveryProvince(option);
  };
  const handleChangeDeliveryDistrict = async (option) => {
    setDeliveryDistrict(option);
  };

  const handleChangeInvoiceWard = (option) => {
    setInvoiceWard(option);
  };
  const handleChangeInvoiceProvince = async (option) => {
    setInvoiceProvince(option);
  };
  const handleChangeInvoiceDistrict = async (option) => {
    setInvoiceDistrict(option);
  };

  const handleChangeInvoiceAddress = async (address) => {
    setInvoiceAddress(address);
  };

  const handleLanguageToggle = () => {
    const newLanguage = En ? "vi" : "en";
    setEn(!En);
    dispatch(toggleLanguage(newLanguage));
  };


 
  useEffect(() => {
    const defaultLanguage = En ? "en" : "vi"; // Adjust if you have a specific default
    dispatch(toggleLanguage(defaultLanguage));
    
    setDeliveryAddress(addressInit.address);
    setInvoiceAddress(addressInit.address);
    setDeliveryAddressInit(addressInit);

    setInvoiceAddressInit(addressInit);
    setDateOfBirth('2000-01-01');
    setIsLoading(false);
   // getCusttomer();
  }, [dispatch, En, isLoading]);

  const handleName = (e) => {
    setCustomerName(e.target.value);
    setErrClientName('');
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail('');
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
    setErrPhone('');
  };

  const handleChangeDeliveryAddress = (address) => {
    setDeliveryAddress(address);
  };

  // ============= Event Handler End here ===============
  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!customerName) {
      setErrClientName("Enter your name");
      isValid = false;
    }
    if (!email) {
      setErrEmail("Enter your email");
      isValid = false;
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid email");
        isValid = false;
      }
    }
    if (!phone) {
      setErrPhone("Enter your phone number");
      isValid = false;
    }

    if (!deliveryAddress) {
      setErrAddress("Enter your address");
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    let addressInvoice = `${invoiceAddress}, ${invoiceWard.label}, ${invoiceDistrict.label}, ${invoiceProvince.label}`;

    let addressDeli = `${deliveryAddress}, ${deliveryWard.label}, ${deliveryDistrict.label}, ${deliveryProvince.label}`;
    let props = {
      customer_id: customer.customer_id,
      customer_name: customerName,
      customer_phone: phone,
      email: email,
      address: addressDeli,
      address_delivery: addressDeli,
      address_invoice: addressInvoice,
      dateOfBirth: dateOfBirth,
      custAddresses: [
        {
          address: deliveryAddress,
          provinceCode: deliveryProvince.value.toString(),
          districtCode: deliveryDistrict.value.toString(),
          wardCode: deliveryWard.value.toString(),
          addressType: CONST_DATA.DELIVERY_ADDRESS_TYPE,
        },
        {
          address: invoiceAddress,
          provinceCode: invoiceProvince.value.toString(),
          districtCode: invoiceDistrict.value.toString(),
          wardCode: invoiceWard.value.toString(),
          addressType: CONST_DATA.INVOICE_ADDRESS_TYPE,
        },
      ],
    };
    console.log(props);
    //return;
   
    let data= {
      phone:phone
    };
    //setLoading(true);
    const response  = await sendLoginOtp(data)
    //setLoading(false);
    if (response.success) {
      dispatch(createUserRegister(props));
      navigate('/login-otp', { state: { phone: phone , returnUrl:'/'} });
    }

    // let response = await UpdateCustomer(props);
    // if (!response.success) {
    //   toast.error(response.message);
    // } else {
    //   navigate("/home");
    // }
  };
  return (
    <div className="w-full h-screen flex items-center justify-start">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Get started for free
            </h1>
            <p className="text-base">Create your account to access more</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with Fox
              </span>
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
              nisi dolor recusandae consectetur!
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all BSM services
              </span>
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
              nisi dolor recusandae consectetur!
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
              nisi dolor recusandae consectetur!
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              © Fox
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full  h-full flex flex-col justify-center">
        {successMsg ? (
          <div className="w-[500px]">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signin">
              <button
                className="w-full h-10 bg-primeColor rounded-md text-gray-200 text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Sign in
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-4/5  h-[100vh] flex items-center justify-center">
            <div
              className="absolute top-4 right-4"
              onClick={handleLanguageToggle}
            >
              <Image
                className="w-6 h-6 object-cover cursor-pointer"
                imgSrc={En ? uk : vi}
              />
            </div>
            <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
                <LableItem code="P.SignUp.Header"></LableItem>
              </h1>

              {/* First Section */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {/* Client Name */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    <LableItem code="P.Signup.FullName"></LableItem>
                  </p>
                  <input
                    onChange={handleName}
                    value={customerName}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    placeholder={GetContentLangByCode(
                      "P.EditProfile.FullName.Plh"
                    )}
                  />
                  {errClientName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errClientName}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    <LableItem code="P.EditProfile.Email"></LableItem>
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder={GetContentLangByCode(
                      "P.Signup.EditProfile.Plh"
                    )}
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Phone Number */}
                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    <LableItem code="P.EditProfile.Phone"></LableItem>
                  </p>
                  <input
                    onChange={handlePhone}
                    value={phone}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                   
                    placeholder={GetContentLangByCode("P.Signup.Phone.Plh")}
                  />
                  {errPhone && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPhone}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    <LableItem code="P.EditProfile.BirthDate"></LableItem>
                  </p>
                  <input
                    onChange={(e) => {
                      setDateOfBirth(e.target.value);
                    }}
                    value={dateOfBirth}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="date"
                  />
                  {/* {errDeliveryDate && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDeliveryDate}
                  </p>
                )} */}
                </div>
              </div>

              {/* Second Section - Address Inputs */}

              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">

              {!isLoading ? (
                <>
                 <AddressInput
                  isHorizontal={true}
                  handleChangeDistrict={handleChangeDeliveryDistrict}
                  handleChangeWard={handleChangeDeliveryWard}
                  handleChangeProvince={handleChangeDeliveryProvince}
                  handleAddressChange={handleChangeDeliveryAddress}
                  addressInit= {deliveryAddressInit}
                  sectionName="Địa chỉ giao hàng"
                />
                </>
              ) : null}
                

               
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">
              {!isLoading ? (
                <>
                <AddressInput
                  isHorizontal={true}
                  handleChangeDistrict={handleChangeInvoiceDistrict}
                  handleChangeWard={handleChangeInvoiceWard}
                  handleChangeProvince={handleChangeInvoiceProvince}
                  handleAddressChange={handleChangeInvoiceAddress}
                  addressInit= {invoiceAddressInit}
                  sectionName="Địa chỉ hóa đơn"
                />
                </>
              ) : null}
             
              </div>

              {/* Checkbox and Submit */}
              <div className="flex flex-col mt-6 gap-4">
               
                <LoadingButton
                  btName="Confirm"
                  onButtonClick={handleSubmit}
                  className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-1/4 text-base font-medium h-10 rounded-md  duration-300"
                >
                  <LableItem code="P.EditProfile.Confirm"></LableItem>
                </LoadingButton>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
