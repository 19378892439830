import _ from 'lodash'
const isNullOrUndefinedOrStringEmpty = (obj) => {
    if(_.isNil(obj)) return true;
    
    if( typeof  obj==='string'){
      if(obj.trim()==='') return true;
    };
    return false;
}
const hasDataResponse = (obj)=>{
  try{
    if(_.isNil(obj)) return false;
    if(_.isNil(obj.results)) return false;
    if(_.isNill(obj.results[0])) return false;
    return true;
  }
  catch(error){
    return false;
  }
 
}
const GetMainResponseData = (response)=>{
  if(hasDataResponse(response)){
    return response.results[0];
  }
  return null;
}
const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
export {isNullOrUndefinedOrStringEmpty, EmailValidation, hasDataResponse, GetMainResponseData}