import BackendApi from "../common.js/backendApi";

import { fetchData } from "../common.js/http";
import httpFactory from "../common.js/httpFactoty";

async function loginWithOtp(data) {
  let props = {
    url: BackendApi.loginWithOtp.url,
    method: BackendApi.loginWithOtp.method,
    body: data,
  };
  return await fetchData(props);
}
async function sendLoginOtp(data) {
  let props = {
    url: BackendApi.sendLoginOtp.url,
    method: BackendApi.sendLoginOtp.method,
    body: data,
  };
  return await fetchData(props);
}
async function getCustByPhone(phone) {
  let url = `${BackendApi.getCustByPhone.url}?phone=${phone}`;
  let props = {
    url: url,
    method: BackendApi.getCustByPhone.method,
  };
  return await fetchData(props);
}
async function getCustomerByToken() {
 
  let props = {
    url: BackendApi.getCustomerByToken.url,
    method: BackendApi.getCustomerByToken.method,
  };
  return await fetchData(props);
}

// function IsAuthen() {
//   if (localStorage.getItem("token")) {
//     return true;
//   }
//   return false;
// }
async function genToken(customer_no) {

  let body = {
    userId: customer_no,
    username: "Dummy customer",
  }
  const response = await fetch(BackendApi.genToken.url, {
    method: BackendApi.genToken.method,
    //credentials: "include",
    headers: {
      "Accept-Language": 'vn',
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  });

  // let props = {
  //   url: BackendApi.genToken.url,
  //   method: BackendApi.genToken.method,
  //   body: {
  //     userId: customer_no,
  //     username: "Dummy customer",
  //   },
  // };
  // return await fetchData(props);
  return await response.json();
}
async function GetCustomerInfomation(params) {
  let props ={
    url: BackendApi.GetCustomerInfomation.url,
    method: BackendApi.GetCustomerInfomation.method,
  }
  let fact = new httpFactory(props);
  return fact.fetchData();
}
async function UpdateCustomer(params) {
  let props ={
    url: BackendApi.UpdateCustomer.url,
    method: BackendApi.UpdateCustomer.method,
    body: params
  }
  let fact = new httpFactory(props);
  return fact.fetchData();
}
async function LogoutApp(params) {
  let props ={
    url: BackendApi.Signout.url,
    method: BackendApi.Signout.method,
   // body: params
  }
  let fact = new httpFactory(props);
  return fact.fetchData();
}
export {
  loginWithOtp,
  sendLoginOtp,
  //IsAuthen,
  getCustByPhone,
  getCustomerByToken,
  GetCustomerInfomation,
  genToken,
  UpdateCustomer,
  LogoutApp
};
