
const backend_url = `https://api-test.mmvietnam.com:5000/fox/api/v1`;
const auth_url = `https://api-test.mmvietnam.com:5000/fox/fox-gw`;

// const backend_url = `https://api-local.bsmartvina.com/api/v1`;


// const backend_url = `https://api-test.bsmartvina.com/fox/api/v1`;
// const auth_url = `https://api-test.bsmartvina.com/fox/fox-gw`;

const BackendApi = {
    signUp : {
        url : `${backend_url}/signup`,
        method : "post"
    },
    signIn : {
        url : `${backend_url}/signin`,
        method : "post"
    },
    signOut : {
        url : `${backend_url}/logout`,
        method : 'get'
    },
    currentUser : {
        url : `${backend_url}/user-details`,
        method : "get"
    },
    homeBanners : {
        url : `${backend_url}/home-banners`,
        method : "get"
    }, 
    hotPromotions : {
        url : `${backend_url}/hot-promotinons`,
        method : "get"
    },  
    bestSellers : {
        url : `${backend_url}/best-selling`,
        method : "get"
    },   
    productYear : {
        url : `${backend_url}/product-year`,
        method : "get"
    },             
    allUsers : {
        url : `${backend_url}/all-user`,
        method : 'get'
    },
    updateUser : {
        url : `${backend_url}/update-user`,
        method : "post"
    },
    uploadImage : {
        url : `${backend_url}/Files`,
        method : 'post'
    },
    allProducts : {
        url : `${backend_url}/products`,
        method : 'get'
    },
    updateProduct : {
        url : `${backend_url}/products`,
        method  : 'put'
    },
    createProduct : {
        url : `${backend_url}/products`,
        method  : 'post'
    },    
    productByCategory : {
        url : `${backend_url}/Products/Category`,
        method : 'get'
    },
    allCategories : {
        url : `${backend_url}/Categories`,
        method : 'get'
    },
    allBrands : {
        url : `${backend_url}/Brands`,
        method : 'get'
    },        
    viewCart : {
        url : `${backend_url}/Carts`,
        method : 'get'
    },
    addToCartProduct : {
        url : `${backend_url}/CartItems`,
        method : 'get'
    },
    updateCartProduct : {
        url : `${backend_url}/CartItems`,
        method : 'put'
    },
    deleteCartProduct : {
        url : `${backend_url}/CartItems`,
        method : 'delete'
    },
    searchProduct : {
        url : `${backend_url}/RedisSearch`,
        method : 'post'
    },
    getHomeBannerV2 : {
        url : `${backend_url}/Banners`,
        method : 'get'
    },
    allProductsAndCount : {
        url : `${backend_url}/productsAndCount`,
        method : 'get'
    },
    allBrandsByCate : {
        url : `${backend_url}/Brands/category/`,
        method : 'get'
    },   
    allProductPrices : {
        url : `${backend_url}/ProductPrices`,
        method : 'get'
    },
    CreateCart : {
        url : `${backend_url}/Carts`,
        method : 'POST'
    },
    GetCurrentCart : {
        url : `${backend_url}/Carts`,
        method : 'GET'
    },
    GetQuantityItemInCart : {
        url : `${backend_url}/Carts`,
        method : 'GET'
    },
    AddToCart : {
        url : `${backend_url}/Carts`,
        method : 'POST'
    },
    UpdateCardItem : {
        url : `${backend_url}/CartItems`,
        method : 'PUT'
    },
    ChangeCartOwner : {
        url : `${backend_url}/Carts`,
        method : 'PUT'
    },
    GetNewOwnerCart : {
        url : `${backend_url}/Carts`,
        method : 'GET'
    },
    //GetCartByCust
    GetCartByCust : {
        url : `${backend_url}/Carts`,
        method : 'GET'
    },
    RemoveCartItem : {
        url : `${backend_url}/CartItems`,
        method : 'DELETE'
    },
    //RemoveCartItem
    allRating : {
        url : `${backend_url}/Ratings`,
        method : 'get'
    },
    createRating : {
        url : `${backend_url}/Ratings`,
        method : 'post'
    },
    loginWithOtp : {
        url : `${auth_url}/login-otp`,
        method : 'post'
    },
    sendLoginOtp : {
        url : `${auth_url}/send-otp-login`,
        method : 'post'
    },
    getCustByPhone : {
        url : `${auth_url}/get-customer-info-by-phone`,
        method : 'get'
    },
    getCustomerByToken : {
        url : `${auth_url}/get-customer`,
        method : 'get'
    },
    genToken : {
        url : `${backend_url}/getToken`,
        method : 'post'
    },
    getAllStore : {
        url : `${backend_url}/get-all-store`,
        method : 'get'
    },
    getAllProvince : {
        url : `${backend_url}/get-all-province`,
        method : 'get'
    },
    getDistrictByProvinceCode : {
        url : `${backend_url}/get-districts/parent_code`,
        method : 'get'
    },
    getWardByDistrictCode : {
        url : `${backend_url}/get-wards/parent_code`,
        method : 'get'
    },
    CreateOrder : {
        url : `${backend_url}/Orders`,
        method : 'post'
    },
    GetOrdersHistory : {
        url : `${backend_url}/Orders`,
        method : 'get'
    },

    GetOrderById : {
        url : `${backend_url}/Orders`,
        method : 'get'
    },
    GetDeliveryFee : {
        url : `${backend_url}/ServiceStores/DeliveryCode`,
        method : 'get'
    },
    GetDeliveryItems : {
        url : `${backend_url}/DeliveryItem`,
        method : 'get'
    },
    
    SearchProduct : {
        url : `${backend_url}/RedisSearch`,
        method : 'post'
    },
    RetrieveProduct : {
        url : `${backend_url}/products`,
        method : 'get'
    },
    RetrieveProducts : {
        url : `${backend_url}/products`,
        method : 'get'
    },
    AddToCartByCust: {
         url : `${backend_url}/CartItems`,
        method : 'post'
    },
    SearchProductElastic: {
        url: `${backend_url}/EsSearch`,
        method: "post"
    },
    GetCustomerInfomation: {
        url: `${auth_url}/get-customer-web-channel`,
        method: 'get'
    },
    UpdateCustomer: {
        url: `${auth_url}/update-customer`,
        method: 'post'
    },
    GetLocationByWardCode : {
        url : `${backend_url}/get-location-by-ward-code`,
        method : 'get'
    },
    ListAttributes: {
        url: `${backend_url}/Attributes`,
        method: 'GET'
    },
    AddToWishListByCust: {
        url: `${backend_url}/WishList`,
        method: 'POST'
    },
    RemoveFromWishListByCust: {
        url: `${backend_url}/wishlist`,
        method: 'DELETE'
    },
    UpdateWishListToCust: {
        url: `${backend_url}/WishList`,
        method: 'PUT'
    },
    GetWishListByCust: {
        url: `${backend_url}/WishList`,
        method: 'GET'
    },
    GetOrderStatuses: {
        url: `${backend_url}/OrderStatuses`,
        method: 'GET'
    },
    Signout: {
        url: `${auth_url}/logout`,
        method: 'post'
    },
    RefreshTokenGw : {
        url : `${auth_url}/refresh-Token-web`,
        method : 'get'
    },
    RefreshTokenFox : {
        url : `${backend_url}/refresh-token`,
        method : 'get'
    },
}


export default BackendApi