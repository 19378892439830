import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleBrand } from "../../../../redux/FoxSlice";
import { brandList } from "../../../../common.js/db";
import { FoxBrand } from "../../../../common.js/transformation";

import BackendApi from "../../../../common.js/backendApi";
import { fetchData } from "../../../../common.js/http";
import { CONST_DATA } from "../../../../common.js/constData";

const Brand = ({cate_code1,itemsPerBrand}) => {
  const [showBrands, setShowBrands] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const En = useSelector(
    (state) => state.FoxReducer.En
  );  
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [currentSelected, setCurrentSelected] = useState([]);

  useEffect(() => {
    itemsPerBrand(null);
    setCurrentSelected([]);
    const fetchProducts = async () => {
      try {
        console.log(store_code);
        let props = {
          url: `${BackendApi.allBrandsByCate.url}${cate_code1}?page=1&size=10&store_id=${CONST_DATA.STORE_DEFAULT}`,
          method: BackendApi.allBrandsByCate.method,
        }
        console.log(`url_prpp:`);
        console.log(props);
        const data1 = await fetchData(props);
        console.log(data1);
        if (data1.success) {
          setData(data1.results);
        }
        else
          setData(null);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    fetchProducts();
  }, [store_code, En,cate_code1]);
  let filteredData = data;

  const handleToggleBrand = (brand) => {
    setCurrentSelected((prevSelected) => {
      if (prevSelected.some((b) => b.id === brand.id)) {
        // Nếu brand đã tồn tại trong danh sách chọn, loại bỏ nó
        const updatedSelected = prevSelected.filter((b) => b.id !== brand.id);
        itemsPerBrand(updatedSelected.map((b) => b.id).join(','));
        return updatedSelected;
      } else {
        // Nếu brand chưa tồn tại trong danh sách chọn, thêm nó
        const updatedSelected = [...prevSelected, brand];
        itemsPerBrand(updatedSelected.map((b) => b.id).join(','));
        return updatedSelected;
      }
    });
  };

  return (
    <div>
      <div
        onClick={() => setShowBrands(!showBrands)}
        className="cursor-pointer"
      >
        <NavTitle title= {En?"Shop by Brand":"Mua sắm theo thương hiệu"}  icons={true} isMenuOpen={showBrands} />
      </div>
      {showBrands && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {filteredData.map((data) => {
              let brand = FoxBrand(data,En);
              return (
              <li
                key={brand.id} style={{cursor:'pointer'}}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
                onClick={() => handleToggleBrand(brand)}
              >
                <input
                  type="checkbox"
                  id={brand.id}
                  checked={currentSelected.some((b) => b.id === brand.id)}
                  onChange={() => handleToggleBrand(brand)}
                />
                {brand.name}
              </li>
            )}
            )}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Brand;
