import React, { useState, useEffect } from "react";
import OtpInput from "./OtpInput"; // Adjust the path based on your project structure
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LoadingButton } from "../../components/Load/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import {
  loginWithOtp,
  getCustomerByToken,
  UpdateCustomer,
} from "../../services/AccountServices";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import { useContentLangByCode } from "../../components/contentLanguage/ContentFactory";

import {
  createOrUpdateCart,
  reomveUserRegister,
  updateLocalCartOwner,
  updateUserInfo, clearAllWishList, addListToWishList,
  updateUserToken
} from "../../redux/FoxSlice";
import {
  ChangeCartOwner,
  GetNewOwnerCart,
  MergeCartDummnyToIdentityCustomer,
} from "../../services/CartServices";

import { FoxProduct } from "../../common.js/transformation";
import { updateCartItemsFromSever } from "../../redux/FoxSlice";
import { CONST_DATA } from "../../common.js/constData";
import { remove } from "lodash";
import { toast } from "react-toastify";
import {
  updateWishList, getWishList
} from "../../services/WishListServices";

const OTPPage = () => {
  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes countdown
  const location = useLocation();
  const navigate = useNavigate();
  const phone = location.state?.phone;
  const returnUrl = location.state?.returnUrl;
  const products = useSelector((state) => state.FoxReducer.products);
  const userRegister = useSelector((state) => state.FoxReducer.userRegister);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [loginError, setLoginError] = useState('');
  const dispatch = useDispatch();
  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    // You can perform any logic here with the OTP value
  };
  const verifyButtonLabel = useContentLangByCode("P.OtpPage.Verify");
  function updateLocalCartItems(data) {
    let cartItems = data.results[0].CartItems;

    let products = cartItems.map((item) => {
      return FoxProduct(item);
    });
    return products;
  }
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
    if (timeLeft === 0) {
      navigate("/otp-expired", {
        state: { phone: phone, returnUrl: returnUrl ? returnUrl : "/" },
      });
    }
  }, [timeLeft, navigate, phone, returnUrl, loginError]);

  const handleVerify = async (e) => {
    e.preventDefault();
    const otpValue = otp.join(""); // Convert array to string

    setLoading(true);
    let data = {
      phone: phone,
      otp: otpValue,
    };
    setLoginError('');
    let loginResonse = await loginWithOtp(data);
    setLoading(false);

    if (loginResonse.success) {
      const userToken= {
        mm_token: loginResonse.result.token,
        refreshToken: loginResonse.result.refreshToken
      }
      dispatch(updateUserToken(userToken))
      const cust_no =loginResonse.result.customer_no;
      UpdateWishList(cust_no);

      let mergeProps = {
        customer_no: cust_no,
        token: loginResonse.result.token,
        products: products,
      };
      
      await MergeCartDummnyToIdentityCustomer(mergeProps);
      
      let cartResponse = await GetNewOwnerCart(
        cust_no,
        CONST_DATA.STORE_DEFAULT
      );
      
      if (cartResponse.success) {
        if(cartResponse.results.length>0){
          let products = updateLocalCartItems(cartResponse);

          dispatch(updateCartItemsFromSever(products));
          let payload = {
            cart_id: cartResponse.results[0].id,
            customer_no: cust_no,
          };
          dispatch(createOrUpdateCart(payload));
        }
       
      }
      let getCusResponse = await getCustomerByToken();
      console.log(getCusResponse);
      dispatch(updateUserInfo(getCusResponse.result));
      GetWishList();
      navigate(returnUrl);
    }
    else{
      
      setLoginError(loginResonse.message);
    }
  };
  const UpdateWishList = async (new_cusstomer_no) => {

    setLoading(true);
    let data = {
      new_customer_no: new_cusstomer_no,
      channel_code: CONST_DATA.CHANNEL
    };
    let wlResonse = await updateWishList(data);
    setLoading(false);
  };

  const GetWishList = async () => {

    setLoading(true);
    let wl_list_Resonse = await getWishList(1, 100);
    setLoading(false);
    if (wl_list_Resonse.success) {
      var p_list_custom = [];
      if(wl_list_Resonse.results != null && wl_list_Resonse.results.length)
      {
        wl_list_Resonse.results.forEach((item) => {
          p_list_custom.push({product_code:item.product_code});
        });
        dispatch(clearAllWishList());
        dispatch(addListToWishList(p_list_custom));
      }
      else
        dispatch(clearAllWishList());
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-[450px] w-full">
        <form className="flex flex-col items-center">
          {timeLeft > 0 && (
            <>
              <h2 className="mb-6 text-3xl font-semibold text-gray-800">
                <LableItem code="P.OtpPage.Header"></LableItem>
              </h2>
              <OtpInput value={otp} onChange={handleOtpChange} />
            </>
          )}

          <p className="mt-4 text-lg text-gray-600">
            <LableItem code="P.OtpPage.TimeRemaining"></LableItem>:{" "}
            {formatTime(timeLeft)}
          </p>
          <p className="mt-4 text-lg text-red-700">
            {loginError}
          </p>
          {!otp.every((digit) => digit !== "" && !isNaN(digit)) ? (
            <></>
          ) : (
            <>
              {timeLeft > 0 && (
                <LoadingButton
                  onButtonClick={handleVerify}
                  loading={loading}
                  btDisabled={
                    timeLeft === 0 ||
                    !otp.every((digit) => digit !== "" && !isNaN(digit))
                  }
                  btName={verifyButtonLabel}
                  className="mt-4"
                />
              )}
               
            </>
          )}
          <p className="text-sm text-ci font-medium text-center">
              <Link to="/">
                <li
                  key="homepage"
                  className="flex font-normal hover:font-bold justify-center items-center text-lg text-blue-600 hover:underline underline-offset-[4px] decoration-[1px] hover:text-black md:border-r-[2px] border-r-gray-300 hoverEffect last:border-r-0"
                >
                  <LableItem code="C.Multi.BackToHome"></LableItem>
                </li>
              </Link>
            </p>
        </form>
      </div>
    </div>
  );
};

export default OTPPage;
