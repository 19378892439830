import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const En = useSelector((state) => state.FoxReducer.En);  
  const [emailInfo, setEmailInfo] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSubscription = () => {
    if (emailInfo === "") {
      setErrMsg("Please provide an Email !");
    } else if (!emailValidation(emailInfo)) {
      setErrMsg("Please give a valid Email!");
    } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
    }
  };
  return (
    <div className="w-full bg-[#F5F5F3] py-10">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-4  xl:grid-cols-4 px-4 gap-10">
        <div className="md:col-span-2 xl:col-span-2">
          <FooterListTitle title= {En?"Company profile":"Thông tin công ty"} />
          <div className="flex flex-col gap-6">
            <p className="text-base w-full xl:w-[80%]">
            { En? 
              <> 
              Officially operating since June 20, 2013, B’s Mart convenience store chain now owns more than 150 stores spread across Ho Chi Minh City. In the future, B’s Mart aims to rapidly expand nationwide with the goal of becoming the leading convenience store chain in Vietnam. 
              </>
              : 
              <>
                Chính thức hoạt động vào ngày 20/6/2013, hiện nay chuỗi cửa hàng tiện lợi B’s mart đã sở hữu hơn 150 cửa hàng, trải khắp khu vực thành phố Hồ Chí Minh. Trong tương lai, B’s mart sẽ nhanh chóng mở rộng phát triển trên toàn quốc với mong muốn trở thành chuỗi cửa hàng tiện lợi hàng đầu tại Việt Nam.
              </>
            }
            </p>
            <ul className="flex items-center gap-2">
              <a
                href="https://www.youtube.com/@bsmarttuyendung1553"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
              {/* <a
                href="https://github.com/itetrant"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaGithub />
                </li>
              </a> */}
              <a
                href="https://www.facebook.com/bsmart/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a
                href="https://www.linkedin.com/in/bsmart/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div>
          <FooterListTitle title={En?"Shop by":"Mua sắm"}  />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300" onClick={() => navigate('/producttype/7')}>
              {En?"Product of the year":"Sản phẩm của năm"}
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300" onClick={() => navigate('/producttype/1')}>
              {En?"Promotion":"Sản phẩm Khuyến mãi"}
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300" onClick={() => navigate('/producttype/2')}>
              {En?"Best sellers":"Sản phẩm bán chạy"}
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300" onClick={() => navigate('/producttype/6')}>
              {En?"New Arrivals":"Sản phẩm mới"}
            </li>
          </ul>
        </div>
        <div>
          <FooterListTitle title={En?"My Account":"Tài khoản của tôi"}  />
          <ul className="flex flex-col gap-2">
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"  onClick={() => navigate('/edit-profile')}>
            {En?"Profile":"Thông tin"} 
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300" onClick={() => navigate('/order-history')}>
            {En?"Orders":"Đơn hàng"} 
            </li>
            {/* <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Addresses
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Account Details
            </li>
            <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
              Payment Options
            </li> */}
          </ul>
        </div>
        {/* <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to our newsletter." />
          <div className="w-full">
            <p className="text-center mb-4">
              A at pellentesque et mattis porta enim elementum.
            </p>
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600"
              >
                Subscribed Successfully !
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Insert your email ...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
                >
                  Subscribe
                </button>
              </div>
            )}

            <Image
              className={`w-[80%] lg:w-[60%] mx-auto ${
                subscription ? "mt-2" : "mt-6"
              }`}
              imgSrc={paymentCard}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
