import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const GoogleConsentBanner = ({ isEnglish }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    try {
      const consent = localStorage.getItem('cookieConsent');
      const cookiesEnabled = localStorage.getItem('areCookiesEnabled');
      if (!consent && cookiesEnabled) {
        setShowBanner(true);
      }
    } catch (e){
      setShowBanner(false);
    }

  }, []); // Removed dependencies as they are not needed

  const handleAcceptAll = () => {
    try {
        localStorage.setItem('cookieConsent', 'all');
        setShowBanner(false);

        // Set session cookie (necessary for app to run)
        document.cookie = "session=true; path=/;";

        // Update Google Consent Mode to allow optional cookies
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });
    }catch(e) {
      //do nothings
    }
  };

  const handleAcceptNecessary = () => {
    try {
      localStorage.setItem('cookieConsent', 'necessary');
      setShowBanner(false);

      // Set session cookie (necessary for app to run)
      document.cookie = "session=true; path=/;";

      // Update Google Consent Mode to deny optional cookies
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    }catch(e){
      //do nothings
    }
  };

  return (
    <>
      {showBanner && (
        <div className="fixed bottom-0 left-0 right-0 bg-white text-black p-4 text-center shadow-lg z-50">
          <p className="mb-4">
            {isEnglish ? (
              `This site uses cookies to improve your experience. By clicking "Accept All", you allow all cookies (analytics, ads, and session cookies). By clicking "Accept Necessary", you allow only session cookies necessary for the app to run.`
            ) : (
              `Trang web này sử dụng cookie để cải thiện trải nghiệm của bạn. Bằng cách nhấp vào "Chấp nhận tất cả", bạn cho phép tất cả cookie (phân tích, quảng cáo và cookie phiên). Bằng cách nhấp vào "Chỉ chấp nhận cần thiết", bạn chỉ cho phép cookie phiên cần thiết để ứng dụng hoạt động.`
            )}
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleAcceptAll}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
            >
              {isEnglish ? 'Accept All' : 'Chấp nhận tất cả'}
            </button>
            <button
              onClick={handleAcceptNecessary}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
            >
              {isEnglish ? 'Accept Necessary' : 'Chỉ chấp nhận cần thiết'}
            </button>
          </div>
        </div>
      )}

    </>
  );
};

export default GoogleConsentBanner;
