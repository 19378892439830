import React, { useState, useEffect,useRef } from "react";
import { useSelector } from "react-redux";
import NavTitle from "./NavTitle";
import {priceList} from "../../../../common.js/db";



const Price = ({itemsPerPrice,cate_code1}) => {
  const En = useSelector((state) => state.FoxReducer.En);
  const [currentSelected, setCurrentSelected] = useState(null);
  
  useEffect(() => {
    setCurrentSelected(null);
    itemsPerPrice(null);
  }, [cate_code1]);

  const handleRadioChange = (value) => {
    setCurrentSelected(value);
    itemsPerPrice(value);
  };
  return (
    <div className="cursor-pointer">
      <NavTitle title={ En?"Shop by Price":"Mua sắm theo giá"} icons={false} />
      <div className="font-titleFont">
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {priceList.map((pl) => (
            <li
              key={pl._id}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
              onClick={() => handleRadioChange(pl._id)}
            >
               <input
                type="radio"
                value={pl._id}
                checked={currentSelected === pl._id}
                // onChange={handleRadioChange( pl._id)} 
                radioGroup="r_price"
                id={pl._id}
              />
              {En?pl.label_text_en:pl.label_text_vn}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Price;
