import React, { useState, useEffect } from "react";
import ProductV2 from "../home/Products/ProductV2";
import { useSelector } from "react-redux";

const WishListProduct = ({cate_code, cate_name,wishlist}) => {
  const [data, setData] = useState([]);
  const En = useSelector((state) => state.FoxReducer.En);

  const fetchProducts = async () => {
    const wishlist_cate1 = wishlist.filter(item => item.cat === cate_code);
    setData(wishlist_cate1);
  };

  useEffect(() => {
    setData([]); 
    fetchProducts();
  }, [En,wishlist]);

  let filteredData = data;
  
  return (
    <div className="w-full pb-20" style={{}}>
      {/* <h1 style={{padding:"5px",backgroundColor:"green",color:"white",textAlign:"center",fontSize:"30px",marginBottom:"10px"}}>{cate_name}</h1> */}
      <h2 className="text-4xl font-extrabold text-gray-800 mb-12">{cate_name}</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6">
  
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((item) => {
            return (
              <ProductV2
                key={item.product_code}
                product_code={item.product_code}
                slug={item.slug}
                sku={item.sku}
                image={item.image}
                name={item.name}
                price={item.price}
                retail_price={item.retail_price}
                price_ex_vat={item.price_ex_vat}
                retail_price_ex_vat={item.retail_price_ex_vat}
                vat_code={item.vat_code}
                vat_rate={item.vat_rate}
                color={item.color}
                badge={item.badge}
                des={item.des}
                cat={item.cat}
                cat_name={item.cat_name}
                unit={item.unit}
              />
            );
          })
        ) : (
          <div>{En ? "No products available" : "Không có sản phẩm nào khả dụng"}</div>
        )}
  
      </div>
    </div>
  );
};

export default WishListProduct;
