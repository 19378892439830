import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, createOrUpdateCart, updateCartItemsFromSever, updateLocalCartOwner } from "../../../redux/FoxSlice";
import { LableItem } from "../../LabelLanguge/LabelItem";
import CurrencyComponent from '../../../components/Currency/CurrencyFormat';
import Rating from "./Rating";
import { CreateCart,
  GetCurrentCart,
  AddToCart,
  GetQuantityItemInCart,
  UpdateCardItem, } from "../../../services/CartServices";
import { toast } from "react-toastify";
import { FoxProduct } from "../../../common.js/transformation";
import { CONST_DATA } from "../../../common.js/constData";

const ProductInfo = ({ productInfo, ProRating }) => {
  const En = useSelector((state) => state.FoxReducer.En);
  const userInfo =useSelector((state) => state.FoxReducer.userInfo);
  const cart = useSelector((state) => state.FoxReducer.cart)
  const store =useSelector((state) => state.FoxReducer.useSelectedStore);
  const highlightStyle = {
    color: "#d0121a", // Change this to the desired color
    fontWeight: "bold", // Change this to the desired font weight
  };
  const dummny = useSelector((state) => state.FoxReducer.dummyCust);
  const selectedStore = useSelector(
    (state) => state.FoxReducer.useSelectedStore
  );
  console.log(productInfo);
  const renderDescription = () => {
    if (!productInfo.name) {
      return null;
    }
    const description = productInfo?.des?.split(/:(.*?)-/).map((part, index) => {
      return (
        <span key={index} style={index % 2 === 1 ? highlightStyle : {}}>
          {part}
        </span>
      );
    });

    return <>{description}</>;
  };
  const dispatch = useDispatch();
  const  handleAddToCartAndUpdateCartLocal = async(e)=>{
    try {
      let cartData = await CreateOrUpdateCartInfo();
      await AddToCartOnServer(cartData);

      dispatch(
        addToCart({
          product_code: productInfo.product_code,
          name: productInfo.name,
          quantity: 1,
          image: productInfo.image,
          badge: productInfo.badge,
          price: productInfo.price,
          price_ex_vat: productInfo.price_ex_vat,
          retail_price_ex_vat: productInfo.retail_price_ex_vat,
          retail_price: productInfo.retail_price,
          vat_code: productInfo.vat_code,
          vat_rate: productInfo.vat_rate,
          colors: productInfo.color,
        })
      );
    } catch (error) {
    }
    // let customer_no =userInfo?.customers[0]?.customer_no??cart.customer_no;
    // console.log(productInfo);
    // let response  = await AddOrUpdateCart(customer_no, productInfo.product_code, store.store_code.toString());
    // try{
    //   if(response.success){
    //     let cartResponse = await GetCurrentCart(
    //       customer_no,
    //       store.store_code.toString()
    //     );
    //     console.log(cartResponse);
    //     if(cartResponse.success){
         
    //       let products = cartResponse.results[0].CartItems.map((item) => {
    //         return FoxProduct(item);
    //       });
    //       dispatch(updateCartItemsFromSever(products));
    //       let payload = {
    //         cart_id: cartResponse.results[0].id,
    //         customer_no: customer_no,
    //       };
    //       dispatch(createOrUpdateCart(payload));
    //       toast.success(response.message);
    //     }
        
    //   }
    //   else{
    //     toast.error(response.message);
    //   }
    // }
    // catch(error){
    //   toast.error(error);
    // }
    
    
  } 

  const CreateOrUpdateCartInfo = async () => {
    if (userInfo !== null) {
      return await CreateOrUpdateCartInfoAfterLogin();
    }
    return await CreateOrUpdateCartInfoBeforeLogin();
  };
  const CreateOrUpdateCartInfoAfterLogin = async () => {
    let customer_no = userInfo.customers[0].customer_no;

    return await CreateCartInfo(customer_no);
  };
  const CreateOrUpdateCartInfoBeforeLogin = async () => {
    if (cart === null) {
      return await CreateCartInfo(dummny);
    }
    return cart;
  };
  const CreateCartInfo = async (customer_no) => {
    let cartOnServer = await GetCurrentCart(
      customer_no,
      selectedStore.store_code
    );

    var cart_id = 0;
    if (cartOnServer.results.length > 0) {
      cart_id = cartOnServer.results[0].id;
    } else {
      let cart_data = await CreateCart(customer_no, selectedStore.store_code);
      cart_id = cart_data.results[0].id;
    }

    dispatch(
      createOrUpdateCart({ cart_id: cart_id, customer_no: customer_no })
    );
    let newCartInfo = {
      cart_id: cart_id,
      customer_no: customer_no,
    };
    return newCartInfo;
  };
  const AddToCartOnServer = async (newCart) => {
    try {
      let quantity = 1;
      let cartItem = {
        product_code: productInfo.product_code,
        quantity: quantity,
        price: productInfo.retail_price,
        channel_code: CONST_DATA.CHANNEL,
        discount: 0,
        discount_info: null,
        vat_rate: productInfo.vat_rate,
        vat_code: productInfo.vat_code,
        vat_amount: productInfo.price - productInfo.price_ex_vat,
        total: quantity * productInfo.price,
        cart_id: newCart.cart_id,
        //cart===null?localCartId: cart?.cart_id,
        customer_no: newCart.customer_no,
        //cart===null?localCustNo:cart.customer_no
      };
      console.log(newCart.customer_no);
      let existsQuantity = await GetQuantityItemInCart(
        newCart.customer_no,
        selectedStore.store_code,
        productInfo.product_code
      );

      let response;
      if (existsQuantity === 0) {
        response = await AddToCart(cartItem);
      } else {
        cartItem.quantity = existsQuantity + 1;
        response = await UpdateCardItem(cartItem);
      }
      if (response.success) {
        toast.success(response.message);
      } else toast.error(response.message);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.name}</h2>
      <p className="text-2xl font-semibold">

        <CurrencyComponent price={productInfo.retail_price}></CurrencyComponent>


        {(productInfo.price - productInfo.retail_price > 1) && (<>
          <span className="text-xl font-semibold line-through ml-2">
            <CurrencyComponent price={productInfo.price}></CurrencyComponent> /{productInfo.unit}
          </span>
          <span className="text-xs ml-2 inline-flex items-center px-3 py-1 rounded-full bg-green-600 text-white">
            Save
            <CurrencyComponent price={productInfo.price - productInfo.retail_price}></CurrencyComponent> {productInfo.unit}
          </span>
        </>)
        }
      </p>
      <hr />
      <p className="text-base text-gray-600">{renderDescription()}</p>

      <div className="flex items-center">
        {/* <p className="text-sm mr-2"> Leave a review </p> */}
        <Rating rating={Math.floor(ProRating)} />  {/* Hiển thị 4 sao */}
      </div>

      {/* <p className="text-base text-green-600 font-medium">En Stock</p> */}

      <p className="font-medium text-lg">
        {productInfo.color && (
          <span className="font-normal">{En ? "Colors" : "Màu sắc"}:</span>
        )}
        {productInfo.color}
      </p>

      <button
        onClick={
          handleAddToCartAndUpdateCartLocal
          // () =>
          // dispatch(
          //   addToCart({
          //     product_code: productInfo.product_code,
          //     name: productInfo.name,
          //     quantity: 1,
          //     image: productInfo.image,
          //     badge: productInfo.badge,
          //     price: productInfo.price,
          //     retail_price: productInfo.retail_price,
          //     colors: productInfo.color,
          //   })
          // )
        }
        className="w-full py-4 bg-blue-500 hover:bg-blue-600 duration-300 text-white text-lg font-titleFont"
      >
        <LableItem code="C.MultiComponent.AddToCart"></LableItem>
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> {En ? "Category" : "Nhóm sản phẩm"}: {productInfo.cat_name}</span>
        ; <span className="text-base font-medium"> SKU: {productInfo.product_code}</span>
      </p>
    </div>
  );
};

export default ProductInfo;
