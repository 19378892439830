import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import useSEO from "../../hooks/useSEO";
const Home = () => {
  useSEO({title:"Home",description:"Fox Commerce HomePage",keywords:["ecomnerce", "Fox", "shopping"]})  
  return (
    <div className="w-full mx-auto">
      <h1 className="hidden"> "Discover your new favorite items today!" </h1>
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
         <YearProduct />
        {/* <Sale /> */}
        <NewArrivals />
        <BestSellers />
       
        <SpecialOffers />
      </div>
    </div>
  );
};

export default Home;
