import { useSelector } from "react-redux";

export const LableItem=(labelCode)=>{
    const language = useSelector(
        (state) => state.FoxReducer.foxLangugeDictionary
      ); 
    
     //console.log(language);
     const labelItem = language.find(item => item.LabelCode === labelCode.code);
    
      const labelValue =  labelItem ? labelItem.LabelValue : labelCode.code;
    
    return <>
       {labelValue}
    </>
}