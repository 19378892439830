import { useSelector } from "react-redux";
function GetContentLangByCode(code) {
  try{
    const language = useSelector(
      (state) => state.FoxReducer.foxLangugeDictionary
    ); 
  
   //console.log(language);
   const labelItem = language.find(item => item.LabelCode === code);
  
    const labelValue =  labelItem ? labelItem.LabelValue : code;
    return labelValue;
  }
  catch(error){
    console.log(error);
  }
   
}
const useContentLangByCode = (code) => {
  const language = useSelector((state) => state.FoxReducer.foxLangugeDictionary);
  const labelItem = language.find((item) => item.LabelCode === code);
  return labelItem ? labelItem.LabelValue : code;
};
export { GetContentLangByCode, useContentLangByCode };