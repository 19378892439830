import React, { useEffect, useState } from "react";
import CurrencyComponent from "../Currency/CurrencyFormat";
import { Link, useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";
import { useSelector } from "react-redux";
import { LableItem } from "../LabelLanguge/LabelItem";

const OrderHistoryRow = ({ order }) => {
  const isEn = useSelector((state) => state.FoxReducer.En);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  
  const handleOnclick = (order_id) => {
    console.log(order_id);
    navigate(`/order-detail/${order_id}`);
  };
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {windowWidth > 760 && (
        <div
          className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 mb-1 border py-2 gap-4 justify-items-center hover:text-blue-400 cursor-pointer"
          onClick={() => handleOnclick(order.order_id)}
        >
          <>
            <div className="flex items-center justify-center text-sm font-semibold">
              <h1 className="font-titleFont font-semibold">{order.order_id}</h1>
            </div>
            <div className="flex items-center justify-center text-sm font-semibold">
              <h1 className="font-titleFont font-semibold">{order.order_no}</h1>
            </div>
            <div className="flex items-center justify-center text-sm font-semibold">
              <CurrencyComponent
                price={order.total_price_ex_vat + order.total_vat}
              />
            </div>
            <div className="flex items-center justify-center text-sm">
              <span className="font-medium">
                {new Date(order.created_date).toLocaleString()}
              </span>
            </div>
            <div className="flex items-center justify-center font-titleFont font-bold text-sm">
              <p>
                {isEn
                  ? order.OrderStatus.status_name_en
                  : order.OrderStatus.status_name_local}
              </p>
            </div>
            <div className="flex items-center justify-center font-titleFont font-bold text-lg">
              <Link
                className="text-blue-500 hover:text-yellow-500 transition duration-300"
                to={`/order-detail/${order.order_id}`}
              >
                <BiDetail />
              </Link>
            </div>
          </>
        </div>
      )}

      {windowWidth <= 760 && (
        <div
          className="w-full grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-6 mb-1 border py-2 gap-4 justify-start hover:text-blue-400 cursor-pointer"
          onClick={() => handleOnclick(order.order_id)}
        >
          <>
            <div className="flex items-center justify-start text-sm font-semibold ml-10">
              <p>
              <LableItem code="C.OrderHistory.OrderId" />{" "}
                <span className="font-titleFont font-semibold">
                  {order.order_id}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-start text-sm font-semibold ml-10">
              <p>
              <LableItem code="C.OrderHistory.OrderNo" />:{" "}
                <span className="font-titleFont font-semibold">
                  {order.order_no}
                </span>{" "}
              </p>
            </div>

            <div className="flex items-center justify-start text-sm font-semibold ml-10">
              <p>
              <LableItem code="C.OrderHistory.Header.Total" />:
                <CurrencyComponent
                  price={order.total_price_ex_vat + order.total_vat}
                />
              </p>
            </div>

            <div className="flex items-center justify-start text-sm ml-10">
              <p>
              <LableItem code="C.OrderHistory.Header.OrderDate" />:{" "}
                <span className="font-medium">
                  {new Date(order.created_date).toLocaleString()}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-start font-titleFont font-bold text-sm ml-10">
              <p>
              <LableItem code="C.OrderHistory.Header.OrderStatus" />:
                {isEn
                  ? order.OrderStatus.status_name_en
                  : order.OrderStatus.status_name_local}
              </p>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default OrderHistoryRow;
