import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Image from "../designLayouts/Image";
import BackendApi from "../../common.js/backendApi";
import {fetchData}  from  "../../common.js/http";
import { useSelector } from "react-redux";
import slug from "../../utils/slug";


const CustomSlideV2 = ({ item_banner }) => {
  const En = useSelector((state) => state.FoxReducer.En);
  const navigate = useNavigate();

  var Subtext = En?item_banner.content_en: item_banner.content; var imgSrc = item_banner.url; 
  var text = En?item_banner.title_en:item_banner.title;
  var buttonLink = item_banner.hyperlink ?? "";
  var buttonText = En?item_banner.action_en :item_banner.action;
  const isExternal = buttonLink.startsWith("http://") || buttonLink.startsWith("https://");
  

  const handleProductDetails = () => {
    navigate(`/promotion/${slug(En?item_banner.title_en:item_banner.title)}_${item_banner.id}`);
  };

  return (
    <div className="relative flex items-center justify-center bg-gray-100 p-6 md:flex-row flex-col">
      <div className="max-w-lg mb-6 md:mb-0 md:mr-10 text-center md:text-left">
        <h2 className="mb-4 text-3xl md:text-5xl font-bold text-black">
          {text}
        </h2>
        <p className="mb-6 text-lg md:text-2xl text-gray-600">
          {Subtext}
        </p>
        {isExternal ? (
          <a target="_blank" href={item_banner.hyperlink} rel="noopener noreferrer">
            <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
              {buttonText}
            </button>
          </a>
        ) : (
          <a target="_blank" onClick={handleProductDetails} href={item_banner.hyperlink} rel="noopener noreferrer">
            <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
              {buttonText}
            </button>
            </a>
        )}
      </div>
      <div className="ml-0 md:ml-10">
        <Image imgSrc={imgSrc} />
      </div>
    </div>
  );
};

const Banner = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "1%",
          transform: "translateY(-50%)",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
              width: "30px",
              color: "#262626",
              borderRight: "3px #262626 solid",
              padding: "8px 0",
              cursor: "pointer",
            }
            : {
              width: "30px",
              color: "transparent",
              borderRight: "3px white solid",
              padding: "8px 0",
              cursor: "pointer",
            }
        }
      >
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                    width: "25px",
                    color: "#262626",
                    borderRight: "3px #262626 solid",
                    cursor: "pointer",
                    fontSize: "12px",
                  }
                  : {
                    width: "25px",
                    color: "transparent",
                    borderRight: "3px white solid",
                    cursor: "pointer",
                    fontSize: "12px",
                  }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  const [slides, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const En = useSelector((state) => state.FoxReducer.En);
  // Hàm sleep
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  useEffect(() => {
    const fetchProducts = async () => {
      try {

        let props = {
          url:`${BackendApi.getHomeBannerV2.url}?page=1&size=10`,
          method: BackendApi.getHomeBannerV2.method,
        }
       
        const data1 = await fetchData(props);
        if (data1.success) {
          setProducts(data1);
        }
        
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [En]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="w-full bg-white pt-6">
      {<Slider {...settings}>
        {slides.results.map((slide, index) => (
          <CustomSlideV2 key={index} item_banner={slide} />
        ))}
      </Slider>}
    </div>
  );
};

export default Banner;
