import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllProvince,
  getDistrictByProvinceCode,
  getWardByDistrictCode,
} from "../../services/MasterDataServices";
import _ from 'lodash';

import Select from "react-select";
import { LableItem } from "../LabelLanguge/LabelItem";
import { GetContentLangByCode } from "../contentLanguage/ContentFactory";
const AddressInput = (props) => {
  let addr = props?.address?? ''
  const [address, setAddress] = useState(addr);
  const [city, setCity] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errClientName, setErrClientName] = useState("");
  const [errAddress, setErrAddress] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errCountry, setErrCountry] = useState("");
  const [errZip, setErrZip] = useState("");
  // ============= Error Msg End here ===================

  // ============= Event Handler Start here =============
  const [En, setEn] = useState(false);
  const [province, setProvince] = useState(props?.province);
  const [district, setDistrict] = useState(props?.district);
  const [errDistrict, setErrDistrict] = useState("");
  const [wardOptions, setWardOptions] = useState([]);
  const [districtOptions, setDistricOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [errWard, setErrWard] = useState("");
  const [ward, setWard] = useState(props?.ward);
  const wardSelectedCode = props.addressInit?.ward;
  const districtSelectedCode = props.addressInit?.district;
  const provinceSelectedCode = props.addressInit?.province;
  const dispatch = useDispatch();
  const handleChangeWard = (option) => {
    if(_.isNil(option)){
      option= wardOptions[1];
    }
    setWard(option);
    props.handleChangeWard(option);
  };
  const handleChangeProvince = async (option) => {
    console.log(option);
    if(_.isNil(option)){
      option= provinceOptions[1];
    }
    setProvince(option);
    setErrCity("");
    
    let districtsData = await getDistrictByProvinceCode(option.value);
   
    if (districtsData.length > 0) {
      setDistricOptions(districtsData);
      setDistrict(districtsData[0]);
      await handleChangeDistrict(districtsData[0]);
    }
    props.handleChangeProvince(option);
  };
  // const changeExternalProvince = async (option)=>{
  //   props.handleChangeProvince(option);
  // }
  // const changeExternalDistrict = async (option)=>{
  //   props.handleChangeDistrict(option);
  // }
  // const changeExternalWard = async (option)=>{
  //   props.handleChangeWard(option);
  // }
  const handleChangeDistrict = async (option) => {
    if(_.isNil(option)){
      option= districtOptions[1];
    }
    setDistrict(option);
    setErrDistrict("");
    let wardsData = await getWardByDistrictCode(option.value);

    setWardOptions(wardsData);
    await handleChangeWard(wardsData[0]);
    props.handleChangeDistrict(option);
    
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
    setErrAddress("");
    props.handleAddressChange(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    setErrCity("");
  };
  useEffect(() => {
    const getLocations = async () => {
      try {
        
        let provinces = await getAllProvince();
        setProvinceOptions(provinces);
        let localProvinceSelected;
        let provinceSelected = provinces.find((o)=>o.value.toString()===provinceSelectedCode)
        if (provinces.length > 0) {
          if (provinceSelected) {
            setProvince(provinceSelected);
            //handleChangeProvince(provinceSelected);
            props.handleChangeProvince(provinceSelected);
            localProvinceSelected = provinceSelected;
          } else {
            setProvince(provinces[0]);
           // handleChangeProvince(provinceSelected);
             props.handleChangeProvince(provinces[0]);
            localProvinceSelected = provinces[0];
          }
          let districts = await getDistrictByProvinceCode(
            localProvinceSelected.value
          );
          setDistricOptions(districts);
          if (districts.length > 0) {
            let districtSelected= districts.find((o)=>o.value.toString()===districtSelectedCode)
            let localDistrictSeleted;
            localDistrictSeleted = districtSelected ?? districts[0];
            setDistrict(localDistrictSeleted);
            props.handleChangeDistrict(localDistrictSeleted);
            let listWards = await getWardByDistrictCode(
              localDistrictSeleted.value
            );
            let wardSelected = listWards.find((o)=>o.value.toString()===wardSelectedCode)
            if (listWards.length > 0) {
              let localWardSeleted = wardSelected ?? listWards[0];
              setWard(localWardSeleted);
              props.handleChangeWard(localWardSeleted);
            }

            setWardOptions(listWards);
          }
        }
      } catch (error) {
        console.error("Error fetching provinces data:", error);
      }
    };
    setAddress(props?.addressInit?.address)
    getLocations();
  }, [dispatch]);
  return (
    <>
      {props?.isHorizontal ? (
        <>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md space-y-6">
            <h2 className="text-xl font-bold mb-4 text-gray-800">
              {props.sectionName}
              
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Address */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  <LableItem code="C.AddressInput.Address" />
                </label>
                <input
                  onChange={handleAddress}
                  value={address}
                  className="w-full h-10 px-3 text-sm font-medium rounded-lg border border-gray-300 shadow-sm outline-none focus:ring-2 focus:ring-blue-500"
                  type="text"
                  placeholder={GetContentLangByCode(
                    "P.CheckoutProcess.Address.Plh"
                  )}
                />
                {errAddress && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errAddress}
                  </p>
                )}
              </div>

              {/* City */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  <LableItem code="C.AddressInput.City" />
                </label>
                <Select
                  value={province}
                  options={provinceOptions}
                  onChange={handleChangeProvince}
                  isClearable={true} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                />
                {errDistrict && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDistrict}
                  </p>
                )}
                {errCity && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errCity}
                  </p>
                )}
              </div>

              {/* District */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  <LableItem code="C.AddressInput.District" />
                </label>

                <Select
                  value={district}
                  onChange={handleChangeDistrict}
                  options={districtOptions}
                  isClearable={true} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                />
                {errDistrict && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errDistrict}
                  </p>
                )}
              </div>

              {/* Ward */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  <LableItem code="C.AddressInput.Ward" />
                </label>
                <Select
                  value={ward}
                  onChange={handleChangeWard}
                  options={wardOptions}
                  isClearable={true} // Allows the user to clear the selection
                  className="w-full"
                  classNamePrefix="select"
                />
                {errWard && (
                  <p className="text-xs text-red-500 mt-1">
                    <span className="italic mr-1">!</span>
                    {errWard}
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
            
            <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    <LableItem code="C.AddressInput.Address"></LableItem>
                  </p>
                  <input
                    onChange={handleAddress}
                    value={address}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="text"
                    // placeholder={GetContentLangByCode(
                    //   "P.Signup.EditProfile.Plh"
                    // )}
                  />
                  {errAddress && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errAddress}
                    </p>
                  )}
                </div>
                {/* City */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    <LableItem code="C.AddressInput.City" />
                  </label>
                  <Select
                    value={province}
                    options={provinceOptions}
                    onChange={handleChangeProvince}
                    isClearable={true} // Allows the user to clear the selection
                    className="w-full"
                    classNamePrefix="select"
                  />
                  {errDistrict && (
                    <p className="text-xs text-red-500 mt-1">
                      <span className="italic mr-1">!</span>
                      {errDistrict}
                    </p>
                  )}
                  {errCity && (
                    <p className="text-xs text-red-500 mt-1">
                      <span className="italic mr-1">!</span>
                      {errCity}
                    </p>
                  )}
                </div>

                {/* District */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    <LableItem code="C.AddressInput.District" />
                  </label>

                  <Select
                    value={district}
                    onChange={handleChangeDistrict}
                    options={districtOptions}
                    isClearable={true} // Allows the user to clear the selection
                    className="w-full"
                    classNamePrefix="select"
                  />
                  {errDistrict && (
                    <p className="text-xs text-red-500 mt-1">
                      <span className="italic mr-1">!</span>
                      {errDistrict}
                    </p>
                  )}
                </div>

                {/* Ward */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-gray-700 mb-1">
                    <LableItem code="C.AddressInput.Ward" />
                  </label>
                  <Select
                    value={ward}
                    onChange={handleChangeWard}
                    options={wardOptions}
                    isClearable={true} // Allows the user to clear the selection
                    className="w-full"
                    classNamePrefix="select"
                  />
                  {errWard && (
                    <p className="text-xs text-red-500 mt-1">
                      <span className="italic mr-1">!</span>
                      {errWard}
                    </p>
                  )}
                </div>
        </>
      )}
    </>
  );
};

export { AddressInput };
