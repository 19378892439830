import { isRejectedWithValue } from "@reduxjs/toolkit";
import { fetchData } from "../common.js/http";
import { CONST_DATA } from "../common.js/constData";
import BackendApi from "../common.js/backendApi";
const baseUrl=CONST_DATA.FOX_GW
async function getAllStore(){
    let url = `${BackendApi.getAllStore.url}`;
    let props= {
        url:url,
        method:BackendApi.getAllStore.method,
        
    }
    return await fetchData(props)
}
async function getAllProvince(){
    let url = `${BackendApi.getAllProvince.url}`;
    let props= {
        url:url,
        method:BackendApi.getAllProvince.method,
    }
    let response  = await fetchData(props);
    if(response.success){
        return response.results.map((item)=>{
            return {
                value:item.l_code,
                label: item.l_name
            }
        })
    }
    else return [];
}
async function getDistrictByProvinceCode(code){
    
    let url = `${BackendApi.getDistrictByProvinceCode.url}/${code}`;
    let props= {
        url:url,
        method:BackendApi.getDistrictByProvinceCode.method,
        
    }
    
    let response  = await fetchData(props);
    if(response.success){
        return response.results.map((item)=>{
            return {
                value:item.l_code,
                label: item.l_name
            }
        })
    }
    else return [];
}

async function getWardByDistrictCode(code){
    let url = `${BackendApi.getWardByDistrictCode.url}/${code}`;
    let props= {
        url:url,
        method:BackendApi.getWardByDistrictCode.method,
        
    }
    let response  = await fetchData(props);
    if(response.success){
        return response.results.map((item)=>{
            return {
                value:item.l_code,
                label: item.l_name
            }
        })
    }
    else return [];
}
async function  getLocationByWardCode(ward_code) {
    //get-location-by-ward-code
    let url = `${BackendApi.GetLocationByWardCode.url}/${ward_code}`;
    let props= {
        url:url,
        method:BackendApi.GetLocationByWardCode.method,
        
    }
    let response  = await fetchData(props);
    const {
       
        results = [],
       
      } = response || {};

    if(response.success){
        const [location] = results;
        
        return location
    }
    else return null;
    
}

export {getAllStore, getAllProvince, getDistrictByProvinceCode, getWardByDistrictCode, getLocationByWardCode}