import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import ProductV2 from "../../home/Products/ProductV2";
import { useSelector } from "react-redux";
import { FoxProduct_Home_Price } from "../../../common.js/transformation";
import BackendApi from "../../../common.js/backendApi";
import { fetchData } from "../../../common.js/http";
import { priceList } from "../../../common.js/db";
import Image from "../../designLayouts/Image";
import { CONST_DATA } from "../../../common.js/constData";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Pagination = ({ itemsPerPage,itemsPerSort,itemsPerType, cate_code, itemsPrice, itemsBrand }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [data, setData] = useState([]);
  const [data_row_count, setData_row_count] = useState([]);
  const endOffset = itemOffset + itemsPerPage;

  const pageCount = 10;//  Math.ceil(data.length / itemsPerPage);
  const En = useSelector((state) => state.FoxReducer.En);
  const store_code = useSelector((state) => state.FoxReducer.useSelectedStore);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const handlePageClick = (event) => {

    setCurrentPage(event.selected);
    fetchProducts(event.selected + 1);
  };
  const fetchProducts = async (_page_index) => {
    setLoading(true);
    console.log(`itemsBrand:${itemsBrand}`);
    var _price_filter = ""; var _brand_filter = ""; var _sort = "";var _attribute = "";var _cate = "";
    if (itemsPrice && itemsPrice > 0) {
      var item = priceList.find((item) => item._id === itemsPrice);
      _price_filter += `&price_min=${(item.priceOne * 1000).toFixed(0)}&price_max=${(item.priceTwo * 1000).toFixed(0)}`;
    }
    if (itemsBrand && itemsBrand !== "") {
      _brand_filter = `&brand_id=${itemsBrand}`;
    }
    if(itemsPerSort && itemsPerSort !== "")
    {
      _sort = "&sorting_direction=" + itemsPerSort + "&sorting_column=normal_price_in_vat";
    }
    if(itemsPerType && itemsPerType >0)
    {
      _attribute = "&attribute_code=" + itemsPerType;
    }
    if(cate_code != null && cate_code != "0")
      {
        _cate = "&category_code=" + cate_code;
      }
    try {
      let props = {
        url: `${BackendApi.allProductPrices.url}?page=${_page_index ?? 1}&size=${itemsPerPage}&${_cate}&active=true&published=true&store_id=${CONST_DATA.STORE_DEFAULT}&scopes=["ProductOrigin","ProductCategory","ProductAttribute","ProductPrice","ProductImage"]${_price_filter}${_brand_filter}${_sort}&count=true${_attribute}`,
        method: BackendApi.allProductPrices.method,
      }
      
      
      const data1 = await fetchData(props);
      if (data1.success) {
        setData(data1.results);
        setData_row_count(data1.count);
        var _total = (_page_index) * itemsPerPage;
        setItemOffset(_total > data1.count ? data1.count : _total);
        setItemStart(((_page_index - 1) * itemsPerPage) + 1);
      }
      else {
        setData(null);
        setData_row_count(0);
      }

    } catch (error) {
      setError(error.message);
      console.error("Failed to fetch categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchProducts(1); // for Fetch Api only
  }, [itemsPerType,En, store_code, cate_code, itemsPerPage, itemsPrice, itemsBrand,itemsPerSort]);

  useEffect(() => {
    // Scroll to the top when itemOffset changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [itemOffset]);
  let filteredData = data;
  let _total_row = data_row_count;

  if (loading) {
    return (
      // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      //   <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
      // </div>
      <div className="grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
        <div className="w-full" style={{filter:'blur(4px)'}}>
          <div className="w-full relative group">
            <div className="max-w-80 max-h-80 relative overflow-y-hidden">
              <div>
              <Image className="w-full h-full" imgSrc="../sampleImage.jpg" />
              </div>
            </div>
            <div className="max-w-80 py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
              <div className="flex items-center justify-between font-titleFont">
                <h2 className="text-sm text-primeColor font-bold">XUONG UC GA C.P VN 1</h2>
                <p className="text-[#767676] text-[14px]"> 31.000&nbsp;₫</p></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((data) => {
            let item = FoxProduct_Home_Price(data, En);
            return (
              <div className="w-full" key={item.product_code}>
                <ProductV2
                  product_code={item.product_code}
                  slug={item.slug}
                  sku={item.sku}
                  image={item.image === null || item.image === '' ? `${window.location.origin}/sampleImage.jpg` : item.image}
                  name={item.name}
                  price={item.price}
                  retail_price={item.retail_price}
                  price_ex_vat={item.price_ex_vat}
                  retail_price_ex_vat={item.retail_price_ex_vat}
                  vat_code={item.vat_code}
                  vat_rate={item.vat_rate}
                  color={item.color}
                  badge={item.badge}
                  des={item.des}
                  cat={item.cat}
                  cat_name={item.cat_name}
                  unit={item.unit}
                />
              </div>
            );
          })
        ) : (
          <div>{En ? "No products available" : "Không có sản phẩm nào khả dụng"}</div>
        )}
      </div>
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        {filteredData && filteredData.length > 0 ? (
          (() => {
            let _pageCount = _total_row / itemsPerPage;
            if ((_pageCount * itemsPerPage) < _total_row)
              _pageCount = _pageCount + 1;
            return (
              <ReactPaginate
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={_pageCount}
                previousLabel=""
                forcePage={currentPage}
                pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
                pageClassName="mr-6"
                containerClassName="flex text-base font-semibold font-titleFont py-10"
                activeClassName="bg-black text-white"
              />
            );
          })()
        ) : (
          <div></div>
        )}
        {filteredData && filteredData.length > 0 ? (
          (() => {
            return (
              <p className="text-base font-normal text-lightText">
                {En ? `Products from ${itemStart} to ${itemOffset} of ${_total_row}` : `Sản phẩm từ ${itemStart} đến ${itemOffset} trong số ${_total_row}`}
              </p>);
          })()
        ) : (
          <div></div>
        )}


      </div>
    </div>
  );
};

export default Pagination;
