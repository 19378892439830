//Fox reducer
//TODO call API addToCart/resetCart
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { brandList, categoryList } from "../common.js/db";
import { foxLangugeEn } from "../common.js/foxLanguageEn";
import { foxLangugeVn } from "../common.js/foxLanguageVn";


const initialState = {
  userInfo: null,
  products: [],
  productsSelected: [],
  cart: null,
  checkedBrands: [],
  checkedCategorys: [],
  En: true,
  dummyCust: null,
  NavBrands: [brandList],
  NavCategories: [categoryList],
  foxLangugeDictionary: [],
  isLoading: false,
  stores: [],
  useSelectedStore: {},
  deliveryInfomation: {

  },
  userToken: null,
  wishlist: [],
  userRegister: null,
  retryLocker : {
    retryTimes: 0,
    lastRetry: new Date()
  }
};

export const FoxSlice = createSlice({
  name: "FoxCommerce",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) => item.product_code === action.payload.product_code
      );

      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
      // if(state.userInfo!=null){
      //   state.cart.customer_no=uuidv4();
      // }
      // else{
      //   state.cart.customer_no=state.userInfo?.customers[0]?.customer_no;
      // }
      const labelItem = state.foxLangugeDictionary.find(
        (item) => item.LabelCode === "T.Toast.AddToCart"
      );
      const labelValue = labelItem ? labelItem.LabelValue : "T.Toast.AddToCart";
      // Dispatch a success toast
      //toast.success(labelValue);
    },
    increaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item.product_code === action.payload.product_code
      );
      if (item) {
        item.quantity++;
        // Dispatch a success toast
      }
    },
    drecreaseQuantity: (state, action) => {
      const item = state.products.find(
        (item) => item.product_code === action.payload.product_code
      );
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
        // Dispatch a success toast
      }
    },
    deleteItem: (state, action) => {
      state.products = state.products.filter(
        (item) => item.product_code !== action.payload
      );
      const labelItem = state.foxLangugeDictionary.find(
        (item) => item.LabelCode === "T.Toast.RemoveFromCart"
      );
      const labelValue = labelItem
        ? labelItem.LabelValue
        : "T.Toast.RemoveFromCart";
      // Dispatch a success toast
      toast.error(labelValue);
    },
    resetCart: (state) => {
      state.products = [];
      // Dispatch a success toast
    },

    toggleBrand: (state, action) => {
      const brand = action.payload;
      const isBrandChecked = state.checkedBrands.some((b) => b.id === brand.id);

      if (isBrandChecked) {
        state.checkedBrands = state.checkedBrands.filter(
          (b) => b.id !== brand.id
        );
      } else {
        state.checkedBrands.push(brand);
      }
    },

    toggleCategory: (state, action) => {
      const cat = action.payload;
      state.checkedCategory = cat;
      // const isCategoryChecked = state.checkedCategorys.some(
      //   (b) => b.category_code === cat.category_code
      // );

      // if (isCategoryChecked) {
      //   state.checkedCategorys = state.checkedCategorys.filter(
      //     (b) => b.category_code !== cat.category_code
      //   );
      // } else {
      //   state.checkedCategorys.push(cat);
      // }
    },

    resetCategory: (state) => {
      state.checkedCategory = {};
    },

    toggleLanguage: (state, action) => {
      const language = action.payload;
      if (language && language.toLowerCase() === "en") {
        state.En = true;
        state.foxLangugeDictionary = foxLangugeEn;
      } else {
        state.En = false;
        state.foxLangugeDictionary = foxLangugeVn;
      }
      state.userLanguage = language;
    },
    changeLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateOrderDelivery: (state, action) => {
      state.deliveryInfomation = action.payload;
    },

    updateNavBrands: (state, action) => {
      state.NavBrands = action.payload;
    },
    updateNavCategories: (state, action) => {
      state.NavCategories = action.payload;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setListStores: (state, action) => {
      state.stores = action.payload;
    },
    setUseSelectedStore: (state, action) => {
      state.useSelectedStore = action.payload;
    },
    createOrUpdateCart: (state, action) => {
      if (state.cart === null) state.cart = {};
      state.cart = {
        ...state.cart,
        cart_id: action.payload.cart_id,
        customer_no: action.payload.customer_no,
      };
    },
    createOrUpdateDummy: (state, action) => {
      state.dummyCust = action.payload;
      state.userInfo =null;
      state.userToken.mm_token=null;
    },
    clearIdentifiedUser: (state, action) => {
      state.userInfo =null;
      state.userToken.mm_token=null;
    },
    initLocalCart: (state, action) => {
      //if(state.cart ===null) state.cart={cart_id: a}
      //state.cart= {...state.cart, cart_id:action.payload.cart_id, customer_no:action.payload.customer_no }
    },
    updateLocalCartOwner: (state, action) => {
      state.cart.customer_no = action.payload;
    },
    updateCartItemsFromSever: (state, action) => {
      state.products = action.payload;
    },
    updateItemsUserSelected: (state, action) => {
      state.productsSelected = action.payload;
    },
    addToWishList: (state, action) => {
      state.wishlist = state.wishlist.filter((item) => item !== null);
      if (state.wishlist != null && state.wishlist.length > 0) {
        const item = state.wishlist.find(
          (item) => item.product_code === action.payload.product_code
        );
        if (!item) {
          state.wishlist.push(action.payload);
        }
      } else state.wishlist.push(action.payload);
    },

    removeFromWishList: (state, action) => {
      state.wishlist = state.wishlist.filter((item) => item !== null);
      const index = state.wishlist.findIndex(
        (item) => item.product_code === action.payload.product_code
      );

      if (index !== -1) {
        state.wishlist.splice(index, 1); // Xóa sản phẩm khỏi wishlist
      }
    },

    clearAllWishList: (state) => {
      state.wishlist = [];
    },
    addListToWishList: (state, action) => {
      state.wishlist = state.wishlist.filter((item) => item !== null);
      action.payload.forEach((newItem) => {
        if (state.wishlist != null && state.wishlist.length > 0) {
          const item = state.wishlist.find(
            (item) => item.product_code === newItem.product_code
          );
          if (!item) {
            state.wishlist.push(newItem);
          }
        } else state.wishlist.push(newItem);
      });
    },

    createUserRegister: (state, action) => {
      state.userRegister = action.payload;
    },
    reomveUserRegister: (state, action) => {
      state.userRegister = null;
    },
    logout: (state, action) => {
      state.userInfo = null;
      //if (state.cart === null) state.cart = {}
      state.cart = { customer_no: state.dummyCust };
      //state.cart = { ...state.cart, cart_id: action.payload.cart_id, customer_no: action.payload.customer_no }
      state.products = [];
      state.deliveryInfomation = null;
      state.userToken=null;
    },
    updateUserToken: (state, action) => {
      state.userToken = {
        ...state.userToken,
        mm_token: action.payload.mm_token??state.userToken?.mm_token,
        refreshToken: action.payload.refreshToken??state.userToken.refreshToken,
        token: action.payload.token??state.userToken?.token,
      };
    },
    increaseRetryTimes : (state, action)=>{
      var currentTimes = state.retryLocker?.retryTimes?? 0;
      state.retryLocker.retryTimes = currentTimes+1;
      state.retryLocker.lastRetry = new Date().toISOString()
    },
    resetLocker : (state, action)=>{
      
      state.retryLocker.retryTimes = 0;
      state.retryLocker.lastRetry = new Date()
    }
  },
});

export const {
  addToCart,
  increaseQuantity,
  drecreaseQuantity,
  deleteItem,
  resetCart,
  toggleBrand,
  toggleCategory,
  toggleLanguage,
  resetCategory,
  updateNavBrands,
  updateNavCategories,
  changeLoading,
  updateOrderDelivery,
  updateUserInfo,
  setListStores,
  setUseSelectedStore,
  createOrUpdateCart,
  createOrUpdateDummy,
  updateLocalCartOwner,
  updateCartItemsFromSever,
  updateItemsUserSelected,
  addToWishList,
  removeFromWishList,
  createUserRegister,
  reomveUserRegister,
  clearAllWishList,
  addListToWishList,
  logout,
  updateUserToken,
  increaseRetryTimes,
  resetLocker,
  clearIdentifiedUser
} = FoxSlice.actions;
export default FoxSlice.reducer;
