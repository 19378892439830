const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  function ConvertToDate(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }
  function IsValidTimeFromTo(from, to){
    const timeFrom = ConvertToDate(from);
    const timeTo = ConvertToDate(to);
    if (timeFrom < timeTo) {
      return true;
    } 
    return false;
  }
  function IsMoreThenDate(dateString, dateInput){
    let date =dateInput.setHours(0, 0, 0, 0);
    const dateToCompare = new Date(dateString);
   
    if (dateToCompare.setHours(0, 0, 0, 0) <= date){
      return false;
    }
    return true;
  }
  function DateToString(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  
  export {formatDateTime, IsValidTimeFromTo,IsMoreThenDate, DateToString}