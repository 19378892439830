import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PromotionProduct from "../../components/Promotion/PromotionProduct";
import useSEO from "../../hooks/useSEO";
import BackendApi from "../../common.js/backendApi";
import { fetchData } from "../../common.js/http";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
const useHelmet = () =>{
    return(
        <Helmet>
        <title>Promotion</title>
        <meta name="description" content="Promotion"/>
        <meta name="keywords" content={`bsm, bsmart, ecommerce, fox-commerce, shopping, Promotion`} />
        {/* Ensure these are unique and replaced */}
        <meta property="og:description" content="Promotion" data-react-helmet="true" />
        <meta property="og:title" content="Promotion" data-react-helmet="true" />
        <meta property="og:image" content="https://api-test.mmvietnam.com:5000/fox/assets/images/banners/web-banner-1.png" data-react-helmet="true" />
    </Helmet>
    )
}

const Promotion = () => {
    const { promotion_id } = useParams();  // Nhận title_id từ URL
    const id = promotion_id.split('_').pop();  // Tách id từ title_id, ví dụ "12" từ "promotion_hot_12"
    console.log("ban_id:" + id);
    const [slides, setProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const En = useSelector((state) => state.FoxReducer.En);
    useSEO({ title: "Promotion", ogImage:"https://api-test.mmvietnam.com:5000/fox/assets/images/banners/web-banner-1.png", description: "Fox Commerce Promotion HomePage", keywords: ["ecomnerce", "Fox", "shopping", "Promotion"] })

    // Ref để lưu trữ phần tử sẽ được scroll tới
    const targetRef = useRef(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {

                let props = {
                    url: `${BackendApi.getHomeBannerV2.url}?page=1&size=10`,
                    method: BackendApi.getHomeBannerV2.method,
                }

                const data1 = await fetchData(props);
                if (data1.success) {
                    setProducts(data1);
                }

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [En]);

    useEffect(() => {
        if (slides && slides.results.length > 0 && id) {
            // Đợi một chút để đảm bảo phần tử đã được render
            setTimeout(() => {
                const element = targetRef.current;
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        }
    }, [slides, id]);
    
    return (
        <div className="w-full mx-auto">
            {useHelmet}
            <div className="max-w-container mx-auto px-4">
                {slides && slides.results.length > 0 ? (
                    slides.results.map((data) => {
                        if (data.navigate_id !== 'out app') {
                            return (
                                <PromotionProduct  ref={data.id === id ? (element) => { 
        targetRef.current = element;
        console.log("Ref element:", element); // Log để kiểm tra element
    } : null} key={data.id} item_banner={data} />
                            );
                        } else {
                            return null;
                        }
                    })
                ) : (
                    <div>{En ? "No products available." : "Không có sản phẩm nào khả dụng."}</div>
                )}
            </div>
        </div>
    );
};

export default Promotion;
