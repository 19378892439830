import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";

const RatingDialog = ({ open, onClose, onSubmit,reset }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [agree, setAgree] = useState(false);
  const En = useSelector((state) => state.FoxReducer.En);

  useEffect(() => {
    if (open) {
      setRating(0);
      setComment("");
      setAgree(false);
    }
  }, [open, reset]);

  const handleSubmit = () => {
    if (rating > 0 && agree) {
      onSubmit({ rating, comment });
      onClose(); // Close the dialog after submitting
    } else {
      alert(En?"Please select a rating and agree to the policy.":"Vui lòng chọn xếp hạng và đồng ý với chính sách.");
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{zIndex:100000000}}>
      <div className="bg-white p-6 rounded-lg w-11/12 max-w-lg relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-xl mb-4">Đánh giá sản phẩm</h2>
        
        <div className="flex justify-between mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <div
              key={star}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => setRating(star)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={rating >= star ? "#d4af37" : "none"}  
                viewBox="0 0 24 24"
                stroke={rating >= star ? "#d4af37" : "gray"} 
                className="h-8 w-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                />
              </svg>
              <span className="text-sm mt-1">
                {star === 1 ? "Rất tệ" : star === 2 ? "Tệ" : star === 3 ? "Bình thường" : star === 4 ? "Tốt" : "Rất tốt"}
              </span>
            </div>
          ))}
        </div>

        <textarea
          className="w-full p-2 border border-gray-300 rounded mb-4"
          placeholder="Thêm ghi chú của bạn..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <div className="mb-4">
          <label>
            <input
              type="checkbox"
              checked={agree}
              onChange={() => setAgree(!agree)}
            />
            {" "}Tôi đồng ý với <a href="#" style={{color:"blue"}}>Chính sách xử lý dữ liệu</a> cá nhân của B's mart
          </label>
        </div>

        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white py-2 px-4 rounded w-full"
        >
          Gửi đánh giá
        </button>
      </div>
    </div>
  );
};

export default RatingDialog;
