import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./pages/Error/ErrorPage";
import OTPPage from "./pages/Otp/OtpPage";
import OTPExpiredPage from "./pages/Otp/OtpExpired";
import { AuthorizedPage } from "./Auth/AuthorizedPage";
import CheckOutProcess from "./components/Checkout/CheckOutProcess";
import OrderConfirm from "./components/Order/OrderConfirm";
import OrderCompleted from "./components/Order/OrderCompleted";
import EditProfile from "./pages/Account/EditProfile";
import { genToken } from "./services/AccountServices";
//import { getAllStore } from "./services/MasterDataServices";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout, setListStores, toggleLanguage, updateUserToken } from "./redux/FoxSlice";
import { AddressInput } from "./components/Address/AddressInput";
import { persistStore } from 'redux-persist';
import {persistor, store} from './redux/store';
import {
  createOrUpdateDummy,
  setUseSelectedStore,
  createOrUpdateCart,
} from "./redux/FoxSlice";
import {
  WaitingTest,
  CreateCart,
  GetCurrentCart,
  AddToCart,
  GetQuantityItemInCart,
  UpdateCardItem,
} from "./services/CartServices";
import OrderHistory from "./components/Order/OrderHistory";
import OrderDetail from "./components/Order/OrderDetail";
// import { CONST_DATA } from "./common.js/constData";
import Promotion from "./pages/Promotion/Promotion";
import WishList from "./pages/WishList/WishList";
import { Logout } from "./pages/Account/Logout";
import { refreshToken, resetLocker } from "./common.js/http";
import { CONST_DATA } from "./common.js/constData";
import  ErrorBoundary  from "./common.js/ErrorBoundary";
//import { AuthProvider } from './Auth/';
import GoogleConsentBanner from "./utils/GoogleConsentBanner";
const Layout = () => {
   const isLaptop = window.innerWidth >= 1024;
   const padding_value = isLaptop ? '11rem' : '14rem';
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showHeader, setShowHeader] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const threshold = window.innerHeight / 3;
      if (Math.abs(currentScrollY - lastScrollY) > threshold) {
        if (currentScrollY < lastScrollY) {
          // Scrolling up
          setShowHeader(true);
        } else {
          // Scrolling down
          setShowHeader(false);
        }
        setLastScrollY(currentScrollY);
      }
    };
    
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);
  

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <div className="top-0 left-0 w-full z-50 bg-white"> */}
      <div className={`fixed top-0 left-0 w-full z-50 bg-white transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'}`}>
        <Header />
      </div>
      {/* <div className="sticky top-0 left-0 w-full z-40 bg-white"> */}
      <div className={`fixed top-0 left-0 w-full z-40 bg-white transition-transform duration-300 ${showHeader ? 'mt-14' : 'mt-0'}`}>
        <HeaderBottom />
      </div>      
      <div className="mt-44 lg:mt-20">
        {/* Adjust padding based on the combined height of Header and HeaderBottom */}
        <SpecialCase />
        <ScrollRestoration />
        <Outlet />
      </div>
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        {/* Inside Outlet */}
        {/* ==================== Header Navlink Start here ==== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        {/* ====================Main paths===================== */}
        <Route path="/category/:category" element={<Shop />}></Route>
        <Route path="/producttype/:producttype" element={<Shop />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/address-input" element={<AddressInput />}></Route>
        <Route path="/promotion/:promotion_id" element={<Promotion />}></Route>
        <Route path="/wishlist" element={<WishList />}></Route>
        <Route
          path="/edit-profile"
          element={
            <AuthorizedPage>
              <EditProfile />
            </AuthorizedPage>
          }
        ></Route>
        {/* <Route path="/paymentgateway" element={<Payment />}></Route> */}

        <Route path="*" element={<ErrorPage />} />
      </Route>
      {/* Outside Outlet */}
      <Route path="/home" element={<Navigate to="/" />} /> {/* Add this line */}
      <Route
        path="/checkout"
        element={
          <AuthorizedPage>
            <CheckOutProcess />
          </AuthorizedPage>
        }
      ></Route>
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
       
        <Route
          path="/order-confirmation"
          element={
            <AuthorizedPage>
              <OrderConfirm />
            </AuthorizedPage>
          }
        ></Route>
        <Route
          path="/order-history"
          element={
            <AuthorizedPage>
              <OrderHistory />
            </AuthorizedPage>
          }
        ></Route>
        <Route
          path="/order-completed"
          element={
            <AuthorizedPage>
              <OrderCompleted />
            </AuthorizedPage>
          }
        ></Route>
        <Route
          path="/order-detail/:id"
          element={
            <AuthorizedPage>
              <OrderDetail />
            </AuthorizedPage>
          }
        ></Route>
        {/* <Route
          path="/cart"
          element={
            <AuthorizedPage>
              <Cart />
            </AuthorizedPage>
          }
        ></Route> */}
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      <Route path="/login-otp" element={<OTPPage />}></Route>
      <Route path="/otp-expired" element={<OTPExpiredPage />}></Route>
      <Route path="/logout/:return-url" element={<Logout />}></Route>
      
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);

function App() {
  const userInfo = useSelector((state) => state.FoxReducer.userInfo);
  const cart = useSelector((state) => state.FoxReducer.cart);
  const dummy = useSelector((state) => state.FoxReducer.dummyCust);
  const isEn = useSelector((state) => state.FoxReducer.En);
  const userToken = useSelector((state) => state.FoxReducer.userToken);
  //const selectedStore =useSelector((state) => state.FoxReducer.useSelectedStore);
  const selectedStore = useSelector(
    (state) => state.FoxReducer.useSelectedStore
  );
  const [storeSelect, setStoreSelect] = useState("");
  const dispatch = useDispatch();
  
  const inititalData = async () => {
    try {
      //const storesResponse = await getAllStore();

      let store_code;
      if (selectedStore?.store_code !== null) {
        store_code = selectedStore.store_code;
      }
      else store_code = CONST_DATA.STORE_DEFAULT;
      let customer_no = userInfo?.customers[0]?.customer_no;
      resetLocker();
      let language = isEn?'en':'vi';
      dispatch(toggleLanguage(language));
      const dummyId = uuidv4();
      customer_no = customer_no ? customer_no : dummyId;
      if (userInfo === null ) {
        let custForGetToken  = dummy?dummy:dummyId 
        const responseToken = await genToken(custForGetToken);
        if (responseToken.success === true) {
          const userToken  ={
            token: responseToken.results[0]?.token,
            refreshToken: responseToken.results[0]?.refreshtoken
          }
          dispatch(updateUserToken(userToken));
          console.log(userToken);
          dispatch(createOrUpdateDummy(custForGetToken));
        }
      }
     
      if (cart === null) {
        let cartOnServer = await GetCurrentCart(customer_no, store_code);

        var cart_id = 0;
        if (cartOnServer.results.length > 0) {
          cart_id = cartOnServer.results[0].id;
        } else {
          let cart_data = await CreateCart(customer_no, store_code);
          cart_id = cart_data.results[0].id;
        }
        dispatch(
          createOrUpdateCart({ cart_id: cart_id, customer_no: customer_no })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    const initAppData = async () => {
      await inititalData();
    };
    initAppData();
  }, [dispatch]);
  return (
    <div className="font-bodyFont">
      {/* <RouterProvider router={router} />
       */}
       <GoogleConsentBanner isEnglish={isEn}/> {/* Cookie consent banner */}
       <ErrorBoundary>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <RouterProvider router={router} />
      </ErrorBoundary>
    </div>
  );
}

export default App;
