import React, { useState, useRef, useEffect, useCallback } from "react";
import { genToken, LogoutApp } from "../../services/AccountServices";
import {
  clearAllWishList,
  createOrUpdateCart,
  createOrUpdateDummy,
  logout,
  resetCart,
  updateOrderDelivery,
  updateUserInfo,
  updateUserToken,
} from "../../redux/FoxSlice";
import { useDispatch } from "react-redux";
import { CONST_DATA } from "../../common.js/constData";
import { CreateCart } from "../../services/CartServices";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const segments = location.pathname.split("=");
  let returnUrl;
  if (segments.length > 0) {
    returnUrl = "/" + segments[segments.length - 1];
  } else returnUrl = "/";
  useEffect(() => {
    const initDummyData = async () => {
      const dummyId = uuidv4();
      //console.log(dummyId);

      dispatch(logout(null));
      await LogoutApp(null);

      try {
        const responseToken = await genToken(dummyId);

        if (responseToken.success === true) {
          const userToken= {
            token: responseToken.results[0].token,
            refreshToken: responseToken.results[0].refreshtoken
          };
          dispatch(updateUserToken(userToken));

          dispatch(createOrUpdateDummy(dummyId));

          let cart_data = await CreateCart(dummyId, CONST_DATA.STORE_DEFAULT);
          let cart_id = cart_data.results[0].id;
          dispatch(
            createOrUpdateCart({ cart_id: cart_id, customer_no: dummyId })
          );
        }

        dispatch(clearAllWishList());
        navigate(returnUrl);
      } catch (error) {
        console.log(error);
      }
    };
    initDummyData();
  }, [dispatch]);
  return <>logout....</>;
};
export { Logout };
