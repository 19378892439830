import { configureStore } from "@reduxjs/toolkit";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import isStorageAvailable from '../utils/isStorageAvailable'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import FoxReducer from "./FoxSlice";
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const persistConfig = {
  key:  process.env.NODE_ENV ,
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET_KEY || 'mmvietnam.com.key', // Store this in a secure place
      onError: (error) => {
        console.error('Encryption error:', error);
        persistStore(store).purge();
      },
    }),
  ],  
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, FoxReducer);

const isDevelopment = process.env.NODE_ENV === 'development';
export const store = configureStore({
  reducer: { FoxReducer: persistedReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(createStateSyncMiddleware({blacklist: [PERSIST, PURGE],})),
  // Enable Redux DevTools only in development mode
  //devTools: isDevelopment,
});
initMessageListener(store);

export const persistor = isStorageAvailable()? persistStore(store):null;
