import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderHistoryRow from "./OrderHistoryRow ";
import {
  GetOrdersHistory,
  GetOrderStatuses,
} from "../../services/OrderServices";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { LableItem } from "../../components/LabelLanguge/LabelItem";
import ReactPaginate from "react-paginate";
import { GetContentLangByCode } from "../contentLanguage/ContentFactory";

import { DateToString } from "../../utils/DatetimeHelper";
import "../../utils/DatetimeExtension";

import Select from "react-select";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.FoxReducer.userInfo);
  const isEn = useSelector((state) => state.FoxReducer.En);
  const [orderHistory, setOrderHistory] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderStatus, setOrderStatus] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const itemsPerPage = 5;
  const customer_no = customer.customers[0].customer_no;
  const [isCompletedInit, setIsCompletedInit] = useState(false);
  const [minDate, setMinDate] = useState("");
 // const [maxDate, setMaxDate] = useState("");


  const handleFilter = async (e) => {
    
    
    try {
      const response = await GetOrdersHistory(customer_no, selectedOptions, startDate);

      if (response.success) {
        //console.log(response);
        // const orderFilter = response.results.filter((item) =>
        //   new Date(item.created_date).isBetweenIgnoreTime(
        //     new Date(startDate),
        //     new Date(endDate)
        //   )
        // );
        // console.log(orderFilter);
        setOrderHistory(response.results);
      }
      else {
        setOrderHistory([])
      }
      
    } catch (error) {
      console.log(error);
    }
    
  };
  const handleChange = (selected) => {
    setSelectedOptions(selected);
    // console.log(selected);
    // console.log(selectedOptions);
  };
  const initFilter = () => {
    const fromDate = new Date();

    fromDate.setDate(fromDate.getDate() - 30);
    setStartDate(DateToString(fromDate));
    setEndDate(DateToString(new Date()));
  };
  useEffect(() => {
    const fetchOrderHistoryAndOrderStatuses = async () => {
      
      try {
        const response = await GetOrdersHistory(customer_no);
        if (response.success) {
          setOrderHistory(response.results);
        }
      } catch (error) {}
      try {
        const responseOrderStatusese = await GetOrderStatuses();
        if (responseOrderStatusese.success) {
          let options = responseOrderStatusese.results.map((item) => {
            return {
              value: item.status_code,
              label: isEn ? item.status_name_en : item.status_name_local,
            };
          });
          setOrderStatusOptions(options);
        }
      } catch (error) {}
      //setOrderStatus(["10"])

      
      const today = new Date();
      const pastDate = new Date(today.setDate(today.getDate() - 90));
      const formattedToday = today.toISOString().split("T")[0]; // Format to yyyy-mm-dd

      const formattedDate = pastDate.toISOString().split("T")[0]; // Format to yyyy-mm-dd
     setMinDate(formattedDate);
     //setMaxDate(formattedToday);

      setIsCompletedInit(true);
    };

    fetchOrderHistoryAndOrderStatuses();
    initFilter();
  }, [dispatch, customer_no, isCompletedInit, isEn]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = orderHistory.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(orderHistory.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orderHistory.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="max-w-container mx-auto px-4 pt-5 md:pt-5">
      <Breadcrumbs
        title={GetContentLangByCode("C.OrderHistory.Header.Title")}
      />

      {/* Filter Section */}
      <div className=" w-full mb-4 p-6 bg-white shadow-md rounded-lg flex flex-col md:flex-row justify-between items-center gap-6">
        <div className="w-[90%] sm:w-[90%] lg:w-[20%] flex flex-col items-start gap-2">
          <label
            className="text-base font-semibold text-gray-700"
            htmlFor="startDate"
          >
           <LableItem code="C.OrderHistory.OrderFromDate" />
          </label>
          <input
            id="startDate"
            type="date"
            value={startDate}
            min={minDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 p-1 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-primeColor focus:border-transparent"
          />
        </div>

        {/* Order Status Filter */}
        <div className="flex flex-col gap-2 w-[90%]">
          <label
            className="text-base font-semibold text-gray-700"
            htmlFor="orderStatus"
          >
             <LableItem code="C.OrderHistory.OrderStatus" />
           
          </label>

          <Select
            isMulti
            name="colors"
            // value={selectedOptions} // Controlled component: value is derived from state
            onChange={handleChange} // Update state on change
            options={orderStatusOptions}
            className="basic-multi-select w-full py-2"
            classNamePrefix="select"
          />
        </div>
        <div className="flex flex-col gap-2 w-[15%]  items-center">
          {/* Filter Button */}
          <button
            onClick={handleFilter}
            className="bg-primeColor hover:bg-black w-52 text-white py-2 mt-8 px-6 rounded-md font-semibold transition-colors duration-300 h-15"
          >
            <LableItem code="C.OrderHistory.FilterButton" />
          </button>
        </div>
      </div>

      {orderHistory.length > 0 ? (
        <div className="pb-20 w-full overflow-x-auto">
          <div className="hidden md:grid min-w-[600px] h-20 bg-[#F5F7F7] text-primeColor grid-cols-6 place-content-center px-3 text-lg font-titleFont font-semibold">
            {/* Headers */}
            <div className=" flex items-center justify-center font-titleFont font-bold text-lg ml-4">
              <h2>
                <LableItem code="C.OrderHistory.OrderId" />
              </h2>
            </div>
            <div className=" flex items-center justify-center font-titleFont font-bold text-lg ml-4">
              <h2>
                <LableItem code="C.OrderHistory.OrderNo" />
              </h2>
            </div>
            <div className=" flex items-center justify-center font-titleFont font-bold text-lg">
              <h2>
                <LableItem code="C.OrderHistory.Header.Total" />
              </h2>
            </div>
            <div className="flex items-center justify-center font-titleFont font-bold text-lg">
              <h2>
                <LableItem code="C.OrderHistory.Header.OrderDate" />
              </h2>
            </div>
            <div className="flex items-center justify-center font-titleFont font-bold text-lg">
              <h2>
                <LableItem code="C.OrderHistory.Header.OrderStatus" />
              </h2>
            </div>
            <div className="flex items-center justify-center font-titleFont font-bold text-lg">
              <h2>
                <LableItem code="C.OrderHistory.Header.Detail" />
              </h2>
            </div>
          </div>

          {/* Order Items */}
          <div className="w-full mt-5 grid grid-cols-1 gap-4 justify-items-center">
            {currentItems.map((item) => (
              <OrderHistoryRow key={item.order_id} order={item} />
            ))}
          </div>

          {/* Pagination */}
          <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel=""
              pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
              pageClassName="mr-6"
              containerClassName="flex text-base font-semibold font-titleFont py-10"
              activeClassName="bg-black text-white"
            />
            <p className="text-base font-normal text-lightText">
              Orders from {itemOffset + 1} to{" "}
              {Math.min(endOffset, orderHistory.length)} of{" "}
              {orderHistory.length}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <h4 className="mt-[30px] mb-[40px]">No Orders</h4>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
