import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LoadingButton } from '../../components/Load/LoadingButton';
import { sendLoginOtp } from '../../services/AccountServices';
import { LableItem } from '../../components/LabelLanguge/LabelItem';
import {  useContentLangByCode  } from '../../components/contentLanguage/ContentFactory';
import { toast } from 'react-toastify';
const OTPExpiredPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const phone = location.state?.phone;
  const returnUrl = location.state?.returnUrl;
  const [loading, setLoading] = useState(false);

  const handleRenewOtp = async (e) => {
    e.preventDefault();
    let data = { phone: phone };
    setLoading(true);
    try{
      const response = await sendLoginOtp(data);
      setLoading(false);
      if (response.success) {
        navigate('/login-otp', { state: { phone: phone, returnUrl: returnUrl ? returnUrl : '/' } });
      }
    }
    catch(error){
      setLoading(false);
      toast.error(error);
    }
   
  };
  const renewButtonLabel=useContentLangByCode("P.OtpExpired.RenewOtp");
  return (
    <div className="w-full h-screen flex items-center justify-center bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-[450px] w-full text-center">
        <form className="flex flex-col items-center">
          <p className="text-2xl font-semibold text-red-500 mb-4">
            
            <LableItem code="P.OtpExpired.Header"></LableItem>
          </p>
          <LoadingButton
            onButtonClick={handleRenewOtp}
            loading={loading}
            btName={renewButtonLabel}
            className="mt-4"
          />
          <Link to='/' className='text-blue-600 hover:text-blue-800'>
              
              <LableItem code="C.Multi.BackToHome"></LableItem>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default OTPExpiredPage;
